import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { config } from 'utils/config';
import { utils as gmiUtils } from '@ehi/global-marketing-interface';
import { i18n } from 'utils/i18n';
import cn from 'classnames';
import { ANALYTICS, ASSETS, WINDOW_OBJECT_KEYS } from 'constants';
import Button from 'components/Button';
import Picture from 'components/Picture';
import TripCard from 'components/TripCard';
import GenericNotification from 'components/GenericNotification/GenericNotification';
import AssociateReservationBanner from 'components/AssociateReservation/AssociateReservationBanner';
import ConfirmationHeaderPickupStatus from './ConfirmationHeaderPickupStatus';

/**
 * This function is used to keep our code DRY since JS renders different DOM based on the
 * viewport size.
 *
 * @param {boolean} isCancelled - the `ConfirmationHeader` component is used for confirmation *AND* cancellation
 *                                as such this flag (passed in to the component as a prop) is passed down here
 *                                to differentiate between the two use cases (as of now for Analytics purposes)
 */
const renderPrintButton = (isFoundRental, isCancelled) => (
  <Button
    link
    data-dtm-track={utils.analytics.dtm(
      ANALYTICS.UI_BUTTON,
      isCancelled ? ANALYTICS.CANCELLATION : ANALYTICS.CONFIRMATION,
      ANALYTICS.PRINT
    )}
    className='confirmation__header__print'
    onClick={() => utils.print(['.header', isFoundRental ? '.rental-details' : '.confirmation'])}
    ariaText={utils.i18n('confirmation_print')}>
    <span>{utils.i18n('confirmation_print')}</span>
  </Button>
);

const renderFeeOrRefundNotification = ({ i18nKey, amount, policyLinkText, policyLinkHandler }) => (
  <GenericNotification
    messageClass='refund-notification'
    message={
      <span>
        {utils.i18n(
          i18nKey,
          {
            0: (
              <b key={0} className='formatted-price'>
                {amount}
              </b>
            ),
            1: (
              <Button key={1} link onClick={policyLinkHandler}>
                {policyLinkText}
              </Button>
            ),
          },
          {
            jsx: true,
          }
        )}
      </span>
    }
  />
);

/**
 * This component is the main export from this file and renders the Confirmation Header UI.
 *
 * @param {object} props - the React props passed into this component
 *
 * @return {JSX}
 */
const ConfirmationHeader = ({
  isFoundRental,
  confirmationNumber,
  ticketNumber,
  firstName,
  lastName,
  maskedEmail,
  breakpoint,
  isCancelled,
  isCurrentTrip,
  hasLimitedInventoryConfirmationPage,
  vehicleName,
  isVehicleLimitedInventory,
  handlePrepayTermsModal,
  refundObject,
  guaranteedResCardRequired,
  showGuaranteedResModal,
  cancelFee,
  returnLocation,
  vehicleDetails,
  returnTime,
  isTourVoucherReservation,
  isThirdPartyReservation,
  isTourReservation,
  contractDetails,
  blockAssociateProfile,
  openFirstNameConflictModal,
  profileFirstname,
  unauthReservation,
}) => {
  const confirmationModify =
    utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.CONFIRMATION_MODIFY) === window.location.pathname;

  const confirmationHeader = useMemo(
    () => {
      if (isCancelled) {
        return 'cancellation_header';
      }
      if (isCurrentTrip) {
        return 'found_current_rental_details_header';
      }
      if (isFoundRental) {
        return 'found_rental_details_header';
      }
      return 'confirmation_header';
    },
    [isCancelled, isCurrentTrip, isFoundRental]
  );

  const emailCopy = useMemo(
    () => {
      if (isCancelled) {
        return 'cancellation_email_copy';
      }
      if (confirmationModify) {
        return 'modify_confirmation_email_copy';
      }
      return 'confirmation_email_copy';
    },
    [isCancelled, confirmationModify]
  );

  const bannerInfoText =
    vehicleName && isVehicleLimitedInventory
      ? utils.i18n('confirmation_limited_inventory_text_vehicle', [vehicleName])
      : utils.i18n('confirmation_limited_inventory_text');

  return (
    <div className={cn({ 'theme--dark-blue': !isCurrentTrip, 'theme--light-blue': isCurrentTrip })}>
      <div className='row headerWrapper'>
        <header className='confirmation__header'>
          <div className='confirmation__header-logo'>
            <Picture
              className='confirmation__header__alamo-logo'
              src={utils.config.getSiteLogoImage()}
              alt={utils.i18n('progress_bar_alamo_image_alt')}
            />
          </div>
          <div className='confirmation__header__title-row'>
            <h1 className='confirmation__header__title'>{utils.i18n(confirmationHeader)}</h1>
            {(breakpoint.isDesktop || breakpoint.isTablet) && !isCurrentTrip && renderPrintButton(isCancelled)}
          </div>

          <div className='confirmation__header__content-wrapper'>
            {!isCurrentTrip && (
              <p className='confirmation__header__confirmation-number'>
                {utils.i18n('confirmation_number', [confirmationNumber])}
              </p>
            )}
            {!isCurrentTrip && maskedEmail && (
              <p className='confirmation__header__email-confirmation'>
                {utils.i18n(emailCopy, [firstName, maskedEmail])}
              </p>
            )}
            {hasLimitedInventoryConfirmationPage && (
              <GenericNotification
                messageClass='generic-notification__limited'
                title={utils.i18n('limited_inventory_banner_common')}
                message={bannerInfoText}
              />
            )}
          </div>

          {breakpoint.isMobile && !isCurrentTrip && renderPrintButton(isCancelled)}

          {isCancelled &&
            refundObject?.amount &&
            renderFeeOrRefundNotification({
              i18nKey: 'prepay_cancellation_banner_message',
              amount: utils.getFormattedPrice(refundObject.amount),
              policyLinkText: utils.i18n('about_prepay_terms_conditions'),
              policyLinkHandler: handlePrepayTermsModal,
            })}

          {isCancelled &&
            guaranteedResCardRequired &&
            cancelFee?.fee_apply &&
            renderFeeOrRefundNotification({
              i18nKey: 'guaranteed_res_cancelled_notification_banner',
              amount: utils.getFormattedPrice(cancelFee.fee_amount_view),
              policyLinkText: utils.i18n('guaranteed_res_policy_link'),
              policyLinkHandler: showGuaranteedResModal,
            })}

          {utils.config.getIsEnableAssociateReservationToggleFeature() && (
            <AssociateReservationBanner
              confirmationNumber={confirmationNumber}
              firstName={firstName}
              lastName={lastName}
              maskedEmail={maskedEmail}
              contractDetails={contractDetails}
              blockAssociateProfile={blockAssociateProfile}
              openFirstNameConflictModal={openFirstNameConflictModal}
              profileFirstname={profileFirstname}
              unauthReservation={unauthReservation}
            />
          )}

          <div className='confirmation__header__section-row'>
            {isCancelled && (
              <>
                <section className='confirmation__header__tile home-page'>
                  <h3 className='confirmation__header__tile__title'>
                    {utils.i18n('cancel_header_tile_homepage_title')}
                  </h3>
                  <p className='confirmation__header__tile__copy'>{utils.i18n('cancel_header_tile_homepage_copy')}</p>
                  <a href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.HOME_PAGE_URL)} className='link link--arrow'>
                    {utils.i18n('cancel_header_tile_homepage_link')}
                  </a>
                </section>

                <section className='confirmation__header__tile customer-support'>
                  <Picture
                    srcMobile={ASSETS.CUSTOMER_SUPPORT_1}
                    srcTablet={ASSETS.CUSTOMER_SUPPORT_2}
                    src={ASSETS.CUSTOMER_SUPPORT_3}
                    alt={utils.i18n('cancel_header_tile_support_title')}
                    className={'customer-support__image'}
                  />
                  <div className='customer-support__content'>
                    <h3 className='confirmation__header__tile__title'>
                      {utils.i18n('cancel_header_tile_support_title')}
                    </h3>
                    <p className='confirmation__header__tile__copy'>{utils.i18n('cancel_header_tile_support_copy')}</p>
                    <a
                      href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.CUSTOMER_SUPPORT)}
                      className='link link--arrow'>
                      {utils.i18n('cancel_header_tile_support_link')}
                    </a>
                  </div>
                </section>
              </>
            )}
            {!isCancelled && !isCurrentTrip && (
              <ConfirmationHeaderPickupStatus
                isFoundRental={isFoundRental}
                isTourVoucherReservation={isTourVoucherReservation}
                isThirdPartyReservation={isThirdPartyReservation}
                isTourReservation={isTourReservation}
              />
            )}
            {/* TODO: get remaining_days_for_drop_off from GBO when info available */}
            {isCurrentTrip && (
              <TripCard
                isDetailsPage
                currentTripCard
                key={ticketNumber}
                icon='car-front'
                location={returnLocation.name}
                confirmation_number={confirmationNumber}
                vehicleDetails={vehicleDetails}
                // remainingDaysCopy={formatRemainingDays(remaining_days_for_drop_off, 'return')}
                ctaLabel={i18n('trip_card_call_to_modify_cta')}
                locationDetailsID={returnLocation.id}
                showRenterResources
                showAfterHoursCta={returnLocation.after_hours_return}
                return_location={returnLocation}
                dateOfRentInteraction={gmiUtils
                  .getDateTimeObjFromTs(returnTime)
                  .format(config.getDateTimeLongFormat('llll_with_comma'))}
              />
            )}
          </div>
        </header>
      </div>
    </div>
  );
};

ConfirmationHeader.propTypes = {
  confirmationNumber: PropTypes.string,
  ticketNumber: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  maskedEmail: PropTypes.string,
  hasLimitedInventoryExtra: PropTypes.bool,
  reservationStatus: PropTypes.string,
  maskedPhoneNumber: PropTypes.string,
  isCurrentTrip: PropTypes.bool,
  contractDetails: PropTypes.object,
};

export default ConfirmationHeader;

import React, { useEffect, useState } from 'react';
import { i18n } from 'utils/i18n';
import MissingTripsModal from 'components/modals/MissingTripsModal';
import CurrentTrip from 'components/CurrentTrip';
import Title from 'components/Title';

const ManageRentalsUI = (props) => {
  const { lookupRentals, getLocationInfoById } = props;
  const [alreadyGettingLookupLocations, setAlreadyGettingLookupLocations] = useState(false);
  const [lookupRentalsFull, setlookupRentalsFull] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      setAlreadyGettingLookupLocations(true);

      const newLookupRentals = [...lookupRentals];
      let branchId = '';
      let locationData = {};

      // Getting location data for each rental and mapping data to use in the CurrentTrip component
      for (let i = 0; i < newLookupRentals.length; i += 1) {
        const trip = newLookupRentals[i];
        const { branch } = trip.length_of_rental.receiving_location;

        if (branch !== branchId) {
          branchId = branch;
          // eslint-disable-next-line no-await-in-loop
          const locationResult = await getLocationInfoById(branch, true);
          locationData = locationResult?.location;
        }

        trip.length_of_rental.receiving_location = {
          ...trip.length_of_rental.receiving_location,
          ...(locationData || {}),
        };
        trip.return_location = trip.length_of_rental.receiving_location;
        trip.agreement_number = trip.rental_agreement_identifier;
        trip.return_time = trip.length_of_rental.estimated_return_date_time;

        const license_plate = trip.vehicle?.latest_fleet_vehicle.vehicle_asset.registration_plate.number;
        if (license_plate) {
          trip.vehicle_details = {
            license_plate,
          };
        }
        trip.ticket_number = trip.rental_agreement_identifier;
      }

      if (newLookupRentals?.[0]?.return_location?.gps) {
        setlookupRentalsFull(newLookupRentals);
      }
    };

    if (lookupRentals?.length > 0 && !alreadyGettingLookupLocations) {
      fetchLocations();
    }
  }, [lookupRentals, alreadyGettingLookupLocations]);

  return (
    <div className='my-rentals theme--dark-blue'>
      <div className='my-trips'>
        <div className='row'>
          <div className='my-rentals__header'>
            <Title type='h1' text={i18n('manage_your_rental')} />
            <p className='my-rentals__header-des'>{i18n('we_have_scheduled_return')}</p>
          </div>
          {lookupRentalsFull?.length > 0 && <CurrentTrip currentTrips={lookupRentalsFull} />}
        </div>

        <MissingTripsModal />
      </div>
    </div>
  );
};

export default ManageRentalsUI;

import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import cn from 'classnames';
import utils from 'utils';
import { THEMES } from 'constants';

import { propTypes, defaultProps } from './CheckboxPropTypes';

// TODO: Refactor checkbox to use FieldControl?

class Checkbox extends Component {
  static propTypes = propTypes;

  static defaultProps = defaultProps;

  constructor(props) {
    super(props);

    this.state = {
      id: props.id || utils.dom.uuid(),
      checked: props.checked || props.input.value === props.value,
    };
  }

  static getDerivedStateFromProps(props) {
    return props.derivedState ? { checked: props.checked || props.input.value === props.value } : null;
  }

  componentDidMount() {
    const { initialValue, input } = this.props;
    if (initialValue) {
      input.onChange(initialValue);
      this.setState({ checked: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { checked, input } = this.props;

    prevProps.checked !== checked &&
      this.setState({ checked }, () => {
        const val = checked ? input.value || checked : checked;
        input.onChange(val);
      });
  }

  handleToggle = (e) => {
    const { input, onChange, vehicleIndex, categoryIndex, label, isRadio } = this.props;
    const { checked, value } = e.currentTarget;
    this.setState({ checked }, () => {
      let val;
      if (isRadio) {
        val = checked ? (value || checked) : checked;
      } else {
        val = checked ? (input.value || value || checked) : checked;
      }
      input.onChange(val, categoryIndex, vehicleIndex);
      utils.safeExecute(onChange, e, input, val, label);
    });
  };

  renderLabel = (label) => {
    if (Array.isArray(label)) {
      return label.map((labelText, key) => <Fragment key={key}>{labelText}</Fragment>);
    }
    return label;
  };

  render = () => {
    const {
      dataDtmAnalytics,
      name,
      label,
      value,
      required,
      disabled,
      theme = THEMES.DARK_BLUE,
      fill,
      input,
      meta,
    } = this.props;
    const { id, checked } = this.state;
    const { invalid, submitFailed } = meta;

    return (
      <div
        className={cn(`checkbox-field ${theme}`, {
          fill,
          required,
          checked,
          error: submitFailed && (invalid || (required && !value)),
          disabled,
        })}
      >
        <input
          {...input}
          data-dtm-analytics={dataDtmAnalytics}
          id={id}
          type='checkbox'
          name={name}
          aria-labelledby={`label-${id}`}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={this.handleToggle}
        />
        <label id={`label-${id}`} htmlFor={id} className='checkbox-field__label'>
          {label && this.renderLabel(label)}
          {required && <sup>*</sup>}
        </label>
        {required && !checked && submitFailed && (
          <span className='checkbox-field__error-message'>
            {utils.gmi.types(required).isString
              ? required
              : utils.i18n('field_required_dont_forget_to', [label?.toLowerCase()])}
          </span>
        )}
      </div>
    );
  };
}

const CheckboxField = (props) => (
  <Field {...props}>{({ input, meta }) => <Checkbox {...props} input={input} meta={meta} />}</Field>
);

export default CheckboxField;

import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import utils from 'utils';
import useActions from 'hooks/useActions';
import { updateProfileInRes } from 'actions/checkin';
import { onlineCheckInProfileSelector } from 'selectors/checkinSelectors';
import EditDriverProfileForm from './EditDriverProfileForm';

const domainCountryCode = utils.config.getDomainCountry();

/**
 * EditOciProfileForm Component
 * Form for editing oci driver profile
 *
 * @param {object} props - React Props
 * @param {func} props.renderFooter - function used to render Modal footer, takes the onSubmit handler as first param
 * @param {func} props.onSubmitSuccess - handler to run after successful form submit
 * @param {func} props.onSubmitFail - handler to run after failed submit
 *
 * @return {JSX} EditOciProfileForm jsx component
 */
const EditOciProfileForm = ({ onSubmitSuccess, onSubmitFail, form }) => {
  const profile = useSelector(onlineCheckInProfileSelector);
  const driversLicenseCountryCode = profile.license_profile.country_code || domainCountryCode;
  const updateProfileAction = useActions(updateProfileInRes);

  const onSubmit = formData => {
    updateProfileAction(formData).then(resp => (resp.messages ? onSubmitFail?.() : onSubmitSuccess?.()));
  };

  const {
    country_code,
    license_number,
    country_subdivision_code,
    issuing_authority,
    license_issue_date,
    license_expiration_date,
    birth_date,
  } = profile.license_profile;

  const { email, phones } = profile.contact_profile;

  const {
    street_addresses,
    country_subdivision_code: address_country_subdivision_code,
    city,
    postal,
    country_code: address_country_code,
  } = profile.address_profile;

  const initialValues = useMemo(
    () => ({
      license_info: {
        country_code,
        country_subdivision_code,
        issuing_authority,
        license_number,
      },
      license_issue_date: utils.gmi.parseGBODate(license_issue_date),
      license_expiration_date: utils.gmi.parseGBODate(license_expiration_date),
      birth_date,
      contact_info: {
        email,
        phone_set: phones,
      },
      address_info: {
        address_line_1: street_addresses[0],
        address_line_2: street_addresses[1],
        country_code: address_country_code,
        country_subdivision_code: address_country_subdivision_code,
        city,
        postal,
      },
    }),
    [profile]
  );

  useEffect(() => {
    form.change('license_info', { ...initialValues.license_info });
    form.change('birth_date', { ...initialValues.birth_date });
    form.change('license_issue_date', { ...initialValues.license_issue_date });
    form.change('license_expiration_date', { ...initialValues.license_expiration_date });

    form.change('contact_info', { ...initialValues.contact_info });

    form.change('address_info', { ...initialValues.address_info });
  }, []);

  return (
    <EditDriverProfileForm
      submitHandler={onSubmit}
      initialValues={initialValues}
      form={form}
      initialCountryCode={driversLicenseCountryCode}
    />
  );
};

EditOciProfileForm.propTypes = {
  renderFooter: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
};

export default EditOciProfileForm;

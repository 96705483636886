import React, { Component, createRef } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';
import Button from 'components/Button';
import GuidedError from 'components/Form/GuidedError';
import ToolTip from 'components/ToolTip';
import { THEMES, IDS } from 'constants';

/**
 * FieldControl Provides ui state and context such as filled/focused/error/required/attention
 * This should be used as the root container of Form Input elements to contain labels and error states
 *
 * @param {object} props -
 * TODO: Review all props and add documentation as proptypes is out of date
 * @param {string}       props.id - Unique ID
 * @param {string}       props.label - Label For Field
 * @param {string}       props.description - Description For Field
 * @param {string}       props.type - Type For Field
 * @param {string||bool} props.required - Designates required field, passing a string sets a custom error message
 * @param {bool}         props.asterisk - Overrides required prop to show or hide asterisk
 * @param {bool}         props.attention - Attention State (Arrow)
 * @param {bool}         props.guided - Guided Flow
 * @param {bool}         props.disabled - Disabled State
 * @param {bool}         props.error - Error State
 * @param {bool}         props.fill - Full Width
 * @param {bool}         props.showErrorsOnLoad -
 * @param {bool}         props.dontShowError -
 * @param {string||bool} props.initialValueButton -
 * @param {function}     props.initialValueButtonHandler - Handler for remove the initial value button
 * @param {bool}         props.readOnly -
 * @param {string||bool} props.tooltip -
 * @param {bool}         props.forceError -
 * @param {bool}         props.forceFocus -
 * @param {string}       props.errorTheme - sets a component-theme--${errorTheme} class on errors
 *
 * @return {JSX}         FieldControlBase component
 */
class FieldControlBase extends Component {
  state = {
    modifying: false,
  };

  childRef = createRef();

  handleBlur = (e) => {
    const { onBlur, input } = this.props;
    input.onBlur(e);
    utils.safeExecute(onBlur, e, input);
  };

  handleChange = (e) => {
    const { onChange, input } = this.props;
    input.onChange(e);
    utils.safeExecute(onChange, e, input);
  };

  handleFocus = (e) => {
    const { onFocus, input } = this.props;
    input.onFocus(e);
    utils.safeExecute(onFocus, e, input);
  };

  clearInitialValue = () => {
    this.setState({ modifying: true });
    const { onChange, input } = this.props;
    // Creating a fakeEvent to pass an empty string value to final form through input.onChange when user clicks initialValueButton
    const fakeEvent = { target: { value: '' } };
    input.onChange(fakeEvent);
    utils.safeExecute(onChange, fakeEvent, input);
    this.childRef.current?.focus();
  };

  startModifying = () => {
    const { initialValueButtonHandler } = this.props;

    if (initialValueButtonHandler) {
      initialValueButtonHandler(this.clearInitialValue);
    } else {
      this.clearInitialValue();
    }
  };

  _renderGuidedError = (className, error) =>
    (error || this.props.meta.error) && <GuidedError className={className} message={error || this.props.meta.error} />;

  // generateChildProps creates special props that will get attached to the first child node.
  // the first child node should ALWAYS be your input/select
  // we clone this.props.children in render and apply these props to the first child
  generateChildProps = (child, index, errorActive) => {
    // only generate props for the first child, which should be your input
    // other children will be cloned yet untouched, so you can pair other elements with your input
    // ex: <input/><span>descriptive text</span>, or <input type='password' /><button>Show Password</button>
    if (index === this.props.applyInputPropsToChildIndex) {
      const { onBlur, onChange, onFocus, ...props } = this.props;
      const { data, invalid, submitFailed } = props.meta;

      // TODO: Some FieldControls have domNodes as children which trigger React warnings from passing props not meant for
      // DomNodes. Add to domNodeTypes array below if other DomNodes are used as children and cause React warnings
      const domNodeTypes = ['input', 'select'];
      const isDomNode = domNodeTypes.includes(child.type);
      const passwordHintDescription =
        props?.descriptionValue === IDS.passwordRequirementsList ? IDS.passwordRequirementsList : false;

      delete props.children;
      delete props.className;
      delete props.initialValueButton;
      delete props.initialValueButtonHandler;
      delete props.initialValueButtonTracking;
      delete props.isFullWidth;
      delete props.inputType;
      delete props.showDropdownRightIcon;
      delete props.applyInputPropsToChildIndex;
      delete props.tabbingIndex;
      if (isDomNode) {
        delete props.forceError;
        delete props.forceFocus;
        delete props.errorTheme;
        delete props.fill;
        delete props.fill_50;
        delete props.dontShowError;
        delete props.asterisk;
        delete props.dispatch;
        delete props.breakpoint;
        delete props.descriptionValue;
      }

      return {
        ...props,
        ...{
          ...props.input,
          ...(onBlur && { onBlur: this.handleBlur }),
          ...(onChange && { onChange: this.handleChange }),
          ...(onFocus && { onFocus: this.handleFocus }),
          ...(!isDomNode && { showGuidedError: data.isGuided && invalid && submitFailed }),
          ...(!isDomNode && { renderGuidedError: this._renderGuidedError }),
          ...(!isDomNode && { isFocusedActive: data.isFocusedActive }),
          // Commenting this for now, The aria-labelledby attribute on the <input> element is unnecessary, as the label is correctly associated with the <input> element.
          // ...(props.label && { [`aria-labelledby`]: `label-${props.id}` }),
          ...((props.description || passwordHintDescription) && {
            [`aria-describedby`]: passwordHintDescription || `description-${props.id}`,
          }),
          ...(errorActive && {
            [`aria-describedby`]: passwordHintDescription
              ? `error-${props.id} ${passwordHintDescription}`
              : `error-${props.id}`,
          }),
          ...(invalid && submitFailed && { [`aria-invalid`]: true }),
          ...(props.required && { [`aria-required`]: true }),
          ref: this.childRef,
        },
      };
    }

    return {};
  };

  // returns the input type used for the className
  // so all text-based inputs get the same class [text, password, number, etc...]
  getInputType = () => {
    const { type, input } = this.props;

    const fieldInputTypes = ['checkbox', 'radio', 'select'];
    if (input?.type && fieldInputTypes.includes(input.type)) {
      return input.type;
    }
    return fieldInputTypes.includes(type) ? type : 'text';
  };

  render = () => {
    const {
      id,
      label,
      description,
      theme,
      errorTheme,
      required,
      asterisk,
      attention,
      disabled,
      dropdown,
      outline,
      fill,
      input,
      meta,
      helperText,
      children,
      className,
      showErrorsOnLoad,
      dontShowError,
      forceError,
      forceFocus,
      initialValueButton,
      initialValueButtonTracking,
      readOnly,
      tooltip,
      isFullWidth,
      showDropdownRightIcon,
      fill_50,
      tabbingIndex,
    } = this.props;
    const { modifying } = this.state;
    const { value, defaultValue } = input;
    const { data = {}, error, active, invalid, touched, submitFailed, submitError } = meta;
    const { isGuided, isNext } = data;

    // We show an error if:
    // if we have an error
    // OR if there is a meta error and showErrorsOnLoad prop is true
    // OR if not guided flow and they've submitted and interacted and it is (invalid OR required and doesn't have a value)

    const errorActive =
      (error && showErrorsOnLoad) ||
      (!isGuided && submitFailed && touched && (invalid || (required && !value))) ||
      forceError;

    const showForceFocus = forceFocus && !value;

    const statesClassNames = {
      focus: active || showForceFocus,
      filled: value || defaultValue,
      error: errorActive,
      attention: attention && !active,
      required,
      disabled,
      'read-only': readOnly,
      'initial-value-showing': !!initialValueButton && !modifying,
      ...(isGuided && {
        attention: isNext && !active,
      }),
    };

    const inputType = this.getInputType();

    const dropdownRightIcon = {
      'dropdown-right': showDropdownRightIcon,
    };
    return (
      <div
        className={cn('field-control__container', `component-theme--${theme}`, {
          outline,
          fill,
          fill_50,
          focus: active || showForceFocus,
          'field-control__container--full': isFullWidth,
        })}
      >
        <div
          className={cn('field-control', `field-control--${inputType}`, dropdownRightIcon, className, statesClassNames)}
          {...(tabbingIndex && { tabIndex: 0 })}
        >
          {/* Input ==> https://medium.com/@markgituma/passing-data-to-props-children-in-react-5399baea0356 */}
          {React.Children.map(children, (child, index) =>
            React.cloneElement(child, this.generateChildProps(child, index, errorActive))
          )}
          {label && (
            <label id={`label-${id}`} htmlFor={id} className='field-control__label'>
              {label}
              {(asterisk || (required && asterisk !== false)) && <sup aria-hidden='true'>*</sup>}
              {tooltip && (
                <ToolTip placement='top-start'>
                  <label>{tooltip}</label>
                </ToolTip>
              )}
            </label>
          )}

          {initialValueButton && !modifying && (
            <Button
              className={{
                'field-control__text-input-initial-value-button': true,
                dropdown,
              }}
              button={false}
              ariaText={`${utils.i18n('common_delete')} ${label}`}
              onClick={this.startModifying}
              data-dtm-track={initialValueButtonTracking}
            >
              <span>{initialValueButton}</span>
            </Button>
          )}

          {description && (
            <p id={`description-${id}`} className='field-control__description'>
              {description}
            </p>
          )}
        </div>
        {(!isGuided || forceError) && (
          <>
            {required && !value && submitFailed && touched && (
              <span
                id={`error-${id}`}
                className={cn('field-control__error-message', {
                  [`component-theme--${errorTheme}`]: errorTheme,
                })}
              >
                {utils.gmi.types(required).isString
                  ? required
                  : utils.i18n('field_required_dont_forget_to_enter', [label?.toLowerCase()])}
              </span>
            )}
            {invalid && value && submitFailed && !dontShowError && (error || submitError) && (
              <span id={`error-${id}`} className='field-control__error-message'>
                {error || submitError}
              </span>
            )}
          </>
        )}
        {helperText && <span className='field-control__helper-message'>{helperText}</span>}
      </div>
    );
  };
}

FieldControlBase.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.oneOfType([
    PropTypes.string, // Custom Error Message
    PropTypes.bool, // Defaults to Generic Message
  ]),
  asterisk: PropTypes.bool,
  attention: PropTypes.bool,
  guided: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fill: PropTypes.bool,
  showErrorsOnLoad: PropTypes.bool,
  dontShowError: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  fill_50: PropTypes.bool,
  initialValueButton: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  initialValueButtonTracking: PropTypes.string,
  initialValueButtonHandler: PropTypes.func,
  readOnly: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  forceError: PropTypes.bool,
  forceFocus: PropTypes.bool,
  errorTheme: PropTypes.oneOf(Object.values(THEMES)),
  showDropdownRightIcon: PropTypes.bool,
  applyInputPropsToChildIndex: PropTypes.number,
  tabbingIndex: PropTypes.bool,
};

FieldControlBase.defaultProps = {
  type: 'base',
  theme: 'light',
  initialValueButtonTracking: null,
  isFullWidth: false,
  showDropdownRightIcon: false,
  applyInputPropsToChildIndex: 0,
};

const FieldControl = React.forwardRef(({ validations, ...props }, ref) => {
  const { required, composeValidators } = utils.fieldValidation;
  const hasValidations = utils.gmi.types(validations).isArray && validations.length;
  const validate = hasValidations ? composeValidators(required, ...validations) : props.required && required;
  return <Field validate={validate} {...props} ref={ref} component={FieldControlBase} />;
});

export default FieldControl;

import PropTypes from 'prop-types';

export const propTypes = {
  // container props
  // TODO: go through and check which are required
  confirmationNumber: PropTypes.string,
  ticketNumber: PropTypes.string,
  vehicleDetails: PropTypes.object,
  firstName: PropTypes.string,
  maskedEmail: PropTypes.string,
  address: PropTypes.string,
  lat: PropTypes.string,
  long: PropTypes.string,
  locationType: PropTypes.string,
  directions: PropTypes.array,
  id: PropTypes.string,
  formattedPhone: PropTypes.string,
  unFormattedPhone: PropTypes.string,
  prepaySelected: PropTypes.bool,
  breakpoint: PropTypes.object,
  policies: PropTypes.array,
  lastName: PropTypes.string,
  isOneWayRental: PropTypes.bool,
  pickupLocation: PropTypes.object,
  returnLocation: PropTypes.object,
  pickupTime: PropTypes.string,
  returnTime: PropTypes.string,
  vehicleName: PropTypes.string,
  makeModelOrSimilarText: PropTypes.string,
  features: PropTypes.array,
  peopleCapacity: PropTypes.number,
  luggageCapacity: PropTypes.number,
  twoOrFourDoorText: PropTypes.string,
  images: PropTypes.object,
  vehicleCode: PropTypes.string,
  flightNumber: PropTypes.string,
  airline: PropTypes.string,
  priceSummary: PropTypes.object,
  extras: PropTypes.array,
  mileageInfo: PropTypes.object,
  showViewDetailsModal: PropTypes.func.isRequired,
  showLearnMoreModal: PropTypes.func.isRequired,
  showAHViewDetailsModal: PropTypes.func,
  showCancelModal: PropTypes.func.isRequired,
  showModifyModal: PropTypes.func.isRequired,
  hasLimitedInventoryExtra: PropTypes.bool,
  reservationStatus: PropTypes.string,
  maskedPhoneNumber: PropTypes.string,
  selectedBundleWithExtras: PropTypes.object,
  extrasWithoutBundles: PropTypes.array,
  partner_reward_program: PropTypes.shape({
    code: PropTypes.string,
    member_id: PropTypes.string,
    program_name: PropTypes.string,
  }),
  numberOfExtrasSelected: PropTypes.number,
  isThirdPartyReservation: PropTypes.bool,
  isCheckedIn: PropTypes.bool,
  isSkipTheCounterCompleted: PropTypes.bool,
  isCounterBypassEligible: PropTypes.bool,
  cancelRebook: PropTypes.bool,
  refundObject: PropTypes.object,
  countryCode: PropTypes.string,
  thirdPartyReservationSupportNumber: PropTypes.string,
  guaranteedResCardRequired: PropTypes.bool,
  showGuaranteedResModal: PropTypes.func.isRequired,
  cancelFee: PropTypes.object,
  coupons: PropTypes.array,
};

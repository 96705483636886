import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { ANALYTICS, MODAL, MODAL_BUTTON_TYPES } from 'constants';
import { ModalFooter } from 'components/Modal/ModalFooter';
import ExtraDetailWithDrawer from 'components/ReservationFlow/Extras/ExtraDetailWithDrawer';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

/**
 * RecommendedExtrasAlertContent - This is the content for a modal that opens when user tries to
 * ignore recommended extras and continue reservation without adding all of them
 *
 * @param {object} props - React Props
 * @param {string} props.countryCode - Country code of selected reservation
 * @param {string} props.recommendedExtrasNonAdded - Recommended extras for that location user didn't add to reservation
 * @param {function} props.onClickContinueToReview - Function to continue reservation and ignore warning
 * @param {function} props.handleClose - passed from Modal wrapper
 * @return {JSX} Recommended Extras component
 */

const RecommendedExtrasAlertContent = ({
  countryCode,
  recommendedExtrasNonAdded,
  onClickContinueToReview,
  handleClose,
}) => {
  const onDecline = () => {
    handleClose();
    onClickContinueToReview();
  };

  return (
    <>
      <p className='modal-themed__copy'>
        {utils.i18n(`lac_recommended_extra_alert_modal_description_${countryCode?.toLowerCase()}`)}
      </p>
      <div>
        {recommendedExtrasNonAdded.map(extra => (
          <ExtraDetailWithDrawer
            key={extra.code}
            code={extra.code}
            name={extra.name}
            description={extra.detailed_description}
            rateAmount={extra.rate_amount_view}
            totalAmount={extra.total_amount_view}
            rateType={extra.rate_type}
            titleExtraClasses='recommended-alert-modal__drawer'
            expandedExtraClasses='recommend-alert-moda__expanded-area'
          />
        ))}
      </div>

      <ModalFooter
        fullWidthButton
        buttons={[
          {
            type: DECLINE,
            handler: onDecline,
            label: utils.i18n('recommended_alert_modal_continue_review_button_label'),
            largeButton: true,
            analyticsHandler: () =>
              utils.analytics.interaction(
                ANALYTICS.MODAL,
                utils.analytics.prepareKey(MODAL.RECOMMENDED_EXTRAS_ALERT_MODAL),
                utils.i18n('recommended_alert_modal_continue_review_button_label').toLowerCase()
              ),
          },
          {
            type: ACCEPT,
            label: utils.i18n('recommended_alert_modal_go_back_button_label'),
            analyticsValue: utils.i18n('recommended_alert_modal_go_back_button_label').toLowerCase(),
            largeButton: true,
          },
        ]}
      />
    </>
  );
};

RecommendedExtrasAlertContent.propTypes = {
  countryCode: PropTypes.string,
  recommendedExtrasNonAdded: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
      detailed_description: PropTypes.string,
      rate_amount_view: PropTypes.object,
      total_amount_view: PropTypes.object,
      rate_type: PropTypes.string,
    })
  ),
  onClickContinueToReview: PropTypes.func,
};

export default RecommendedExtrasAlertContent;

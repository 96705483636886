import React, { Component } from 'react';
import utils from 'utils';
import TooltipTrigger from 'components/ToolTip/TooltipTrigger';
import ToolTipTemplate from 'components/ToolTip/ToolTipTemplate';

const CAPS_LOCK_KEY = 'CapsLock';
const TAB_KEY = 'Tab';

/**
 * Render Props component to show a tooltip informing the user when they have caps lock activated
 * @param {object} props - React Props
 * @param {function(props)} props.children - render props, children should be a function
 * @return {JSX} CapsLockControl React Component
 */
class CapsLockControl extends Component {
  constructor(props) {
    super(props);

    const tooltipUniqueID = utils.dom.uuid();

    this.state = {
      capsLockActive: false,
      tooltipID: `tooltip-id-${tooltipUniqueID}`,
    };
  }

  componentDidMount() {
    // For MS IE/Edge the native caps lock warning should be disabled
    document.msCapsLockWarningOff = true;
  }

  // This checks for the specific caps lock keycode for when it's directly pressed.
  isTabKey = (event) => event?.key === TAB_KEY;

  // This checks for the specific caps lock keycode for when it's directly pressed.
  isCapsLockKey = (event) => event?.key === CAPS_LOCK_KEY;

  // This checks for the `CapsLock` modifier state, which is only available on mouse or keyboard events;
  isCapsLockModifierOn = (event) => !!event?.getModifierState?.(CAPS_LOCK_KEY);

  /**
   * Pressing the caps lock key will only fire keyup if it is turned 'off',
   * so we can assume it to be false in case this fires with keycode === CAPS_LOCK_KEY_CODE.
   */
  checkKeyUpEvent = (event) => {
    if (this.isCapsLockKey(event) || this.isTabKey(event)) {
      this.setState({ capsLockActive: this.isCapsLockModifierOn(event) });
    }
  };

  /**
   * Pressing the caps lock key will only fire keydown if it is turned 'on'.
   * Also, since the focus doesn't contain the `getModifierState` function,
   * we check for the CapsLock Modifier State when the user presses any key.
   */
  checkKeyDownEvent = (event) => {
    if (this.isCapsLockKey(event) || this.isCapsLockModifierOn(event)) {
      this.setState({ capsLockActive: this.isCapsLockModifierOn(event) });
    }
  };

  // MouseDown has the modifier state getter, so we can verify it immediately when triggered.
  checkOnFieldClick = (event) => {
    if (this.isCapsLockModifierOn(event)) {
      this.setState({ capsLockActive: true });
    }
  };

  // On Blur, we need to clean up the tooltip to avoid leaving it up when the field is not focused.
  resetCapsLockState = () => {
    this.setState({ capsLockActive: false });
  };

  capsLockCheckListeners = {
    onKeyUp: this.checkKeyUpEvent,
    onKeyDown: this.checkKeyDownEvent,
    onMouseDown: this.checkOnFieldClick,
    onBlur: this.resetCapsLockState,
  };

  warningIcon = (<span className='tooltips__warning-icon' />);

  render() {
    const { capsLockActive, tooltipID } = this.state;

    return (
      <TooltipTrigger
        onVisibilityChange={this.toggleCapsLockState}
        placement='top-start'
        // trigger='null' means we're manually controlling state with tooltipShown
        trigger={null}
        tooltipShown={capsLockActive}
        tooltip={ToolTipTemplate(this.warningIcon, utils.i18n('field_password_caps_lock_on'), tooltipID)}
        tooltipID={tooltipID}
      >
        {({ getTriggerProps, triggerRef }) => (
          // we need this wrapper and its' anchor child to properly position the arrow in relation to the field
          <div className='tooltips__caps-lock-wrapper'>
            <span
              {...getTriggerProps({
                className: 'tooltips__caps-lock-anchor',
                ref: triggerRef,
              })}
            />
            {this.props.children(this.capsLockCheckListeners)}
          </div>
        )}
      </TooltipTrigger>
    );
  }
}

export default CapsLockControl;

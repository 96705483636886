import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HashRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import utils from 'utils';
import { getCurrentPageHash } from 'utils/url';
import SKIP_THE_COUNTER from 'constants/skipTheCounter';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import { sessionReservationObjectSelector, isReservationCancelledSelector } from 'selectors/reservationSelectors';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import {
  getCounterBypassEligibleFlag,
  isSkipTheCounterCompletedReservationSelector,
  isOciOrStcFlowNotAllowedSelector,
} from 'selectors/reservationFlowSelectors';
import PostResFlowTemplate from 'components/PostRes/PostResFlowTemplate';
import DiscardReservationModal from 'components/ReservationFlow/SharedModals/DiscardReservationModal';
import STCReviewSelections from './STCReviewSelections';
import STCProtectionsAndPayment from './STCProtectionsAndPayment';
import STCConfirmation from './STCConfirmation';

const { STC_PATH_CONFIG } = SKIP_THE_COUNTER;

/**
 * Skip The Counter Flow SPA component
 * This component handles routing for the Skip The Counter flow's SPA.
 * Logic for routing itself is actually processed by GMA, so this just handles the SPA aspect.
 */
const SkipTheCounterFlow = () => {
  const isFetchingSession = useSelector((state) => isFetchingEndpointSelector(state, { endpoint: 'session/current' }));
  const reservation = useSelector(sessionReservationObjectSelector);
  const isCancelled = useSelector(isReservationCancelledSelector);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const isSkipTheCounterEligible = useSelector(getCounterBypassEligibleFlag);
  const isSkippingTheCounter = useSelector(isSkipTheCounterCompletedReservationSelector);
  const isOciOrStcFlowNotAllowed = useSelector(isOciOrStcFlowNotAllowedSelector);

  const isAuthorMode = utils.config.getIsAuthorModeEnabled();
  const isCustomPathEnabled = utils.config.getIsCustomPathEnabled();

  const currentStepHash = getCurrentPageHash();

  useEffect(() => {
    // if reservation is not oci eligible or is cancelled redirect the user to V/M/C page
    if (
      !isFetchingSession &&
      ((!isSkipTheCounterEligible && !isCustomPathReservation) || isCustomPathReservation || isCancelled) &&
      !isAuthorMode
    ) {
      if (isSkippingTheCounter) {
        // This means that reservation is STC enabled and it's able to access the STC confirmation page
        if (currentStepHash !== STC_PATH_CONFIG.confirmation[0]) {
          utils.loadingRedirect(WINDOW_OBJECT_KEYS.SKIP_THE_COUNTER_FLOW, false, STC_PATH_CONFIG.confirmation[0]);
        }
        return;
      }
      utils.loadingRedirect(
        isCustomPathReservation ? WINDOW_OBJECT_KEYS.CUSTOM_PATH_RENTAL_DETAILS : WINDOW_OBJECT_KEYS.RENTAL_DETAILS
      );
    }

    // If custom path flag is off, redirect to Retail Rental details page
    isCustomPathReservation && !isCustomPathEnabled && utils.loadingRedirect(WINDOW_OBJECT_KEYS.RENTAL_DETAILS);

    // if it's custom path reservation should redirect to custom path flow
    isSkipTheCounterEligible && isCustomPathReservation && utils.loadingRedirect(WINDOW_OBJECT_KEYS.CUSTOM_PATH_FLOW);
  }, [isFetchingSession, isCustomPathReservation, isCancelled, isSkipTheCounterEligible, currentStepHash]);

  useEffect(() => {
    // if the session was already fetched and the reservation is null/invalid...
    if (!isFetchingSession && !reservation && !isAuthorMode) {
      // ...exit the Skip The Counter flow by redirecting back to STC lookup
      window.location.assign(utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.SKIP_THE_COUNTER_LOOKUP));
    }
  }, [isFetchingSession, reservation]);

  useEffect(() => {
    // Validate isOciOrStcFlowNotAllowed only after current session was fetched
    if (!isFetchingSession && isOciOrStcFlowNotAllowed && !isSkippingTheCounter) {
      // After navigating back from Upcoming Reservation page via browser back button, Should redirect to home page
      utils.loadingRedirect(WINDOW_OBJECT_KEYS.HOME_PAGE_URL);
    }
  }, [isFetchingSession]);

  const handleRouteUpdate = () => {
    if (!window.pageXOffset) {
      utils.dom.scrollPage();
    }

    // Dispatch the analytics ready event to indicate a "new page load" (since this is a SPA)
    if (!isOciOrStcFlowNotAllowed) {
      utils.analytics.init(utils.config.getPageInfo());
    }
  };

  return (
    <Router basename={'/'}>
      <PostResFlowTemplate>
        <Route>
          {({ location }) => (
            <utils.OnUpdateWrapper pathname={location.pathname} onUpdate={handleRouteUpdate}>
              <Switch>
                <Route path={STC_PATH_CONFIG.reviewSelections} exact component={STCReviewSelections} />
                <Route path={STC_PATH_CONFIG.skipTheCounter} exact component={STCProtectionsAndPayment} />
                <Route path={STC_PATH_CONFIG.confirmation} exact component={STCConfirmation} />
                <Redirect to={STC_PATH_CONFIG.reviewSelections[0]} from={'/'} />
              </Switch>
            </utils.OnUpdateWrapper>
          )}
        </Route>
        <DiscardReservationModal />
      </PostResFlowTemplate>
    </Router>
  );
};

export default SkipTheCounterFlow;

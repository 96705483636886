import { connect } from 'react-redux';
import { getModifyFlag, getCardSubmissionUrl, getSessionId } from 'selectors/reservationSelectors';
import { cardSubmissionSession } from "actions/reservation/cardSubmissionSession";
import IbmPaymentIframe from './IbmPaymentIframe';

const mapStateToProps = state => ({
  cardSubmissionURL: getCardSubmissionUrl(state),
  sessionId: getSessionId(state),
  isTrueModify: getModifyFlag(state),
});

const mapDispatchToProps = {
  cardSubmissionSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IbmPaymentIframe);

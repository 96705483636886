import { connect } from 'react-redux';
import utils from 'utils';
import { MODAL } from 'constants';
import { debitPaymentObjectSelector, creditPaymentObjectSelector, vehicleRateSelector } from 'selectors';
import { openModal } from "actions/modal/openModal";
import AboutPrepay from './AboutPrepay';

const mapStateToProps = state => ({
  paymentCardData: debitPaymentObjectSelector(state),
  refundObject: creditPaymentObjectSelector(state),
  estimatedTotalView: vehicleRateSelector(state)?.getIn(['price_summary', 'estimated_total_view']),
});

const mapDispatchToProps = {
  handlePrepayTermsModal: () => openModal(MODAL.PREPAY_TERMS_CONDITIONS_MODAL),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(AboutPrepay));

import React, { useState, useEffect, useMemo } from 'react';
import utils from 'utils';
import { i18n } from 'utils/i18n';
import PropTypes from 'prop-types';
import { analyticsInteraction } from 'utils/analytics';
import ANALYTICS from 'constants/analytics';
import useActions from 'hooks/useActions';
import cn from 'classnames';
import Form from 'components/Form/Form';
import TextField from 'components/Form/TextField';
import RadioGroup from 'components/Form/RadioGroup';
import Button from 'components/Button';
import PhoneNumberField from 'components/Form/PhoneNumberField';
import { SESSION_STORAGE } from 'constants/session';
import Storage from 'utils/storageManager';
import { hasGboPhoneInputInvalidError } from 'selectors/errorSelectors';

import {
  onSubmitRentalByPhone,
  onSubmitRentalByEmail,
  onSubmitRentalByConfNumber,
  onLookUpBySearch,
  setCountrySelectedSessionState,
} from 'components/ViewModifyCancelApp/redux/actions';
import { LOOK_UP_BY_TYPE } from 'components/ViewModifyCancelApp/data/constants';

const LookUpRentalForm = ({ lookupType, showNoCurrentRentalModal }) => {
  const [selectedLookupType, setSelectedLookupType] = useState(LOOK_UP_BY_TYPE.PHONE);
  const [phone, setPhone] = useState({});
  const getRentalByPhoneAction = useActions(onSubmitRentalByPhone);
  const getRentalByEmailAction = useActions(onSubmitRentalByEmail);
  const getRentalByConfNumberAction = useActions(onSubmitRentalByConfNumber);
  const onLookUpBySearchAction = useActions(onLookUpBySearch);
  const setCountrySessionState = useActions(setCountrySelectedSessionState);
  const isNADomain = utils.config.isNADomain();

  useEffect(() => {
    if (selectedLookupType === LOOK_UP_BY_TYPE.PHONE) {
      getRentalByPhoneAction();
    } else if (selectedLookupType === LOOK_UP_BY_TYPE.EMAIL) {
      getRentalByEmailAction();
    } else {
      getRentalByConfNumberAction();
    }
  }, [selectedLookupType]);

  const initialData = useMemo(
    () => ({
      rental_info: {
        first_name: '',
        last_name: '',
        phone: {
          phone_number: '',
        },
        email: '',
        trip_identifier: '',
        lookupRentalOptions: selectedLookupType,
      },
    }),
    [selectedLookupType]
  );

  const onSelect = (input) => {
    const { value } = input;
    setSelectedLookupType(value);
  };

  const updateCommitStatePhoneNumber = (data) => {
    if (data.phoneNumber || data.country) {
      const phone_number = {
        phone_country_code: data.country?.country_content?.dialing_code,
        phone_number: data.phoneNumber,
      };
      setPhone(phone_number);
    }
  };

  const onFormSubmit = ({ rental_info }) => {
    const rentalInfo = { ...rental_info, phone };

    let lookup_data;
    if (selectedLookupType === LOOK_UP_BY_TYPE.PHONE) {
      lookup_data = 'phone number';
      const sessionData = Storage.SessionStorage.get(SESSION_STORAGE.COUNTRY_SELECTED, true);
      setCountrySessionState(sessionData);
    } else if (selectedLookupType === LOOK_UP_BY_TYPE.EMAIL) {
      lookup_data = 'email address';
    } else {
      lookup_data = 'confirmation or rental agreement number';
    }

    analyticsInteraction(ANALYTICS.UI_BUTTON, ANALYTICS.CONFIRM_MODIFY, ANALYTICS.FIND_RENTAL, lookup_data);

    return onLookUpBySearchAction(lookupType, rentalInfo).then((response) => {
      if (response?.messages && !response.messages.some(hasGboPhoneInputInvalidError)) {
        showNoCurrentRentalModal();
      }
    });
  };

  return (
    <>
      <Form onSubmit={onFormSubmit} initialValues={initialData}>
        {({ form, handleSubmit }) => (
          <form
            className={cn('rental-lookup-form rental-lookup-form__container rental-lookup-form__flex-mobile-rental')}
            onSubmit={handleSubmit}
            autoComplete='off'
            noValidate
          >
            <div className='rental-lookup-form__row'>
              <TextField
                name='rental_info.first_name'
                label={i18n('review_drive_details_first_name_input_label')}
                fill
                required
              />
              <TextField
                name='rental_info.last_name'
                label={i18n('review_drive_details_last_name_input_label')}
                fill
                required
              />
            </div>
            <p className='lookup-rental__sort'>{i18n('lookup_rental_sort')}</p>
            <div className='rental-lookup-form__radio-group'>
              <RadioGroup
                name='rental_info.lookupRentalOptions'
                onChange={({ target }) => onSelect(target)}
                theme={'light-blue'}
                buttons={[
                  {
                    label: i18n('lookup_rental_phone_number'),
                    value: LOOK_UP_BY_TYPE.PHONE,
                  },
                  {
                    label: i18n('lookup_rental_email_address'),
                    value: LOOK_UP_BY_TYPE.EMAIL,
                  },
                  {
                    label: i18n('lookup_rental_confirmation_number'),
                    value: LOOK_UP_BY_TYPE.CONF_NUMBER,
                  },
                ]}
              />
            </div>
            <div
              className={cn('rental-lookup-form__row', {
                'rental-lookup-form__row-margin': !(selectedLookupType === LOOK_UP_BY_TYPE.PHONE),
              })}
            >
              {selectedLookupType === LOOK_UP_BY_TYPE.PHONE && isNADomain && (
                <p className='lookup-rental__legal'>{i18n('lookup_rental_conditions')}</p>
              )}
              {selectedLookupType === LOOK_UP_BY_TYPE.PHONE && (
                <PhoneNumberField
                  name='rental_info.phone.phone_number'
                  label={i18n('common_phone_number')}
                  fill
                  required
                  autoComplete='tel'
                  inputType='phone'
                  onChange={(data) => updateCommitStatePhoneNumber(data, form)}
                  validations={[utils.fieldValidation.phone, utils.fieldValidation.checkPhoneCode]}
                />
              )}
              {selectedLookupType === LOOK_UP_BY_TYPE.EMAIL && (
                <TextField
                  name='rental_info.email'
                  label={i18n('lookup_rental_email_address')}
                  fill
                  required
                  autoComplete='email'
                  validations={[utils.fieldValidation.email]}
                />
              )}
              {selectedLookupType === LOOK_UP_BY_TYPE.CONF_NUMBER && (
                <TextField
                  name='rental_info.trip_identifier'
                  label={i18n('lookup_rental_confirmation_number')}
                  fill
                  required
                />
              )}
              <Button type='submit' className='rental-lookup-form__search-button'>
                {i18n('find_rental')}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </>
  );
};

LookUpRentalForm.propTypes = {
  lookupType: PropTypes.string,
  showNoCurrentRentalModal: PropTypes.func,
};

export default LookUpRentalForm;

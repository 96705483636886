import React, { useState, useEffect } from 'react';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import ANALYTICS from 'constants/analytics';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { i18n } from 'utils/i18n';
import { capitalizeAll, lowerCapitalize } from 'utils/string';
import utils from 'utils';
import { getArrayValuesAsString } from 'utils/vehicles';
import VehicleClassFilterModalContent from './VehicleClassFilterModalContent';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

/**
 * VehicleClassFilterModal
 *
 * @returns {JSX}
 */

const VehicleClassFilterModal = (props) => {
  const [vehicleState, setVehicleState] = useState(props.vehicle_categories);
  const [vehicleCategoryLoaded, setVehicleCategoryLoaded] = useState(false);

  useEffect(() => {
    setVehicleState(props.vehicle_categories);
  }, [props.vehicle_categories]);

  useEffect(() => {
    if (props.vehicle_categories && props.vehicle_categories.length > 0) {
      setVehicleCategoryLoaded(!vehicleCategoryLoaded);
    }
  }, [props.vehicle_categories]);

  const handleClearAll = () => {
    let updateVehicleCategories = vehicleState;
    const checkedStateList = updateVehicleCategories.map((data) => ({
      ...data,
      carClassChecked: false,
      vehicles: data.vehicles.map((item) => ({ ...item, checked: false })),
    }));
    updateVehicleCategories = [...checkedStateList];
    setVehicleState([...updateVehicleCategories]);
  };

  const handleCheckboxChange = (modifiedData) => {
    setVehicleState(modifiedData);
  };

  const handleSetVehicleState = (vehicle, index) => {
    let vehicleCategories = vehicle;
    const checkedStateList = vehicleCategories[index].vehicles.map((item) => ({ ...item, checked: true }));
    vehicleCategories[index].carClassChecked = true;
    vehicleCategories[index] = { ...vehicleCategories[index], vehicles: checkedStateList };
    vehicleCategories = [...vehicleCategories];
    setVehicleState(vehicleCategories);
  };

  const vehicleFilteredData = (vehicleFilters) => {
    const checkedValues = {};
    const vehicleSelectedCodes = {};

    vehicleFilters.forEach((category) => {
      const filterSelectedVehicles = category.vehicles.filter((vehicle) => vehicle.checked);

      if (category.carClassChecked) {
        checkedValues[category.code] = Array.from(new Set(category.vehicles.map((vehicle) => vehicle.name)));
        vehicleSelectedCodes[category.code] = Array.from(new Set(category.vehicles.map((vehicle) => vehicle.code)));
      } else {
        checkedValues[category.code] = Array.from(new Set(filterSelectedVehicles.map((checked) => checked.name)));
        vehicleSelectedCodes[category.code] = Array.from(
          new Set(filterSelectedVehicles.map((checked) => checked.code))
        );
      }
    });

    return { checkedValues, vehicleSelectedCodes };
  };
  // pass data to store it in redux to access globally
  const handleApplySearchFilter = (handleClose) => () => {
    const { searchbyVehicleFilters, onChange } = props;
    const vehicleFilters = vehicleState;

    // return unique set of elements of selected vehicles  name and codes
    const { checkedValues, vehicleSelectedCodes } = vehicleFilteredData(vehicleFilters);

    const objectKeys = Object.keys(checkedValues);
    let selectedValues = [];
    const selectedValuesCodes = [];
    !!objectKeys &&
      objectKeys.forEach((key) => {
        if (checkedValues[key].length > 0) {
          selectedValues.push(...checkedValues[key]);
          selectedValuesCodes.push(...vehicleSelectedCodes[key]);
        }
      });
    if (selectedValues && selectedValues.length > 0) {
      const searchByVehiclesFilters = {
        selectedValues,
        selectedVehicleFilters: checkedValues,
        selectedVehicleCodes: selectedValuesCodes,
        numOfVehiclesSelected: selectedValues.length,
      };
      searchbyVehicleFilters(searchByVehiclesFilters);
    } else {
      searchbyVehicleFilters({});
    }
    utils.safeExecute(onChange);
    handleClose({ skipAnalytics: true });
    if (selectedValues && Object.keys(selectedValues).length) {
      selectedValues = selectedValues.map((item) => capitalizeAll(item)).sort();

      const selectedStringValues = getArrayValuesAsString(selectedValues);
      utils.analytics.interaction(ANALYTICS.UI_CHECKBOX, capitalizeAll(ANALYTICS.SELECTED), `${selectedStringValues}`);
    }
  };

  const vehicleStateArray = vehicleState.length > 0 ? vehicleState : props.vehicleCategories;
  return (
    <Modal
      modalKey={MODAL.VEHICLE_CLASS_FILTER_MODAL}
      header={utils.i18n('search_by_vehicle_class_modal_title')}
      clearFilter={utils.i18n('clear_all_filter_title')}
      headerRowWithClearButton={true}
      theme={MODAL_THEMES.WHITE}
      customClass={'vehicle-class__modal'}
      customActionOnClose={() => {
        utils.analytics.interaction(
          ANALYTICS.MODAL,
          lowerCapitalize(ANALYTICS.FILTER_BY_VEHICLE_CLASS),
          ANALYTICS.CLOSE
        );
      }}
      // the "clear modal queue" action is often called in place of "close modal" - middleware.js
      // We need to pass skip analytics for the "clearModalQueue" action if we are pushing _analytics.interaction manually on open/close modal
      handleModalQueue={{ skipAnalytics: true }}
      handleClearAll={handleClearAll}
      noStickyHeader
    >
      {({ handleClose }) => (
        <>
          <div className='vehicle-class-content'>
            <div className='modal-themed__horizontal-separator' />
            <p className='modal-themed__paragraph modal-themed__paragraph'>
              {utils.i18n('filter_by_vehicle_class_modal_description')}
            </p>
            <VehicleClassFilterModalContent
              vehicleCategories={vehicleStateArray}
              handleCheckboxChange={handleCheckboxChange}
              handleSetVehicleState={handleSetVehicleState}
            />
          </div>
          <div className='column-reverse-footer'>
            <ModalFooter
              fullWidthButton
              isVehicleClassFilter={true}
              vehicleCategoryLoaded={vehicleCategoryLoaded}
              buttons={[
                {
                  type: DECLINE,
                  label: i18n('common_cancel'),
                  handler: () => handleClose({ skipAnalytics: true }),
                  analyticsHandler: () =>
                    utils.analytics.interaction(
                      ANALYTICS.MODAL,
                      ANALYTICS.FILTER_BY_VEHICLE_CLASS,
                      capitalizeAll(ANALYTICS.CANCEL)
                    ),
                },
                {
                  type: ACCEPT,
                  label: i18n('apply_search'),
                  handler: handleApplySearchFilter(handleClose),
                  analyticsHandler: () =>
                    utils.analytics.interaction(
                      ANALYTICS.MODAL,
                      ANALYTICS.FILTER_BY_VEHICLE_CLASS,
                      capitalizeAll(ANALYTICS.APPLY_FILTER)
                    ),
                },
              ]}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default VehicleClassFilterModal;

VehicleClassFilterModalContent.propTypes = {
  vehicle_categories: PropTypes.object,
  searchbyVehicleFilters: PropTypes.func,
  onChange: PropTypes.func,
};

import React, { Component } from 'react';
import { MODAL, MODAL_THEMES, GLOBAL, ASSETS } from 'constants';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import utils from 'utils';
import RESERVATIONS from 'constants/reservations';
import cn from 'classnames';
import RentalBranchLocation from 'components/RentalBranchLocation';
import ReservationDetails from 'components/Confirmation/ReservationDetails';
import KeyRentalFacts from 'components/KeyRentalFacts';
import PoliciesComponent from 'components/PoliciesComponent';
import Modal from 'components/Modal';
import PrepayTermsModal from 'components/PrepayTermsModal';
import TaxesModalContent from 'components/TaxesModalContent';
import ConfirmCancelReservationModal from 'components/modals/ConfirmCancelReservationModal';
import ModifyReservationModal from 'components/modals/ModifyReservationModal';
import PoliciesModal from 'components/modals/PoliciesModal';
import GuaranteedReservationModal from 'components/modals/GuaranteedReservationModal';
import ExtendRentalInformationModal from 'components/modals/ExtendRentalInformationModal';
import AfterHoursReturnInstructionsModal from 'components/modals/AfterHoursReturnInstructionsModal';
import ReturnDirectionsModal from 'components/modals/ReturnDirectionsModal';
import LearnMoreAboutRoadsideAssistanceModal from 'components/modals/LearnMoreAboutRoadsideAssistanceModal';
import RentalTermsAndConditionsModal from 'components/modals/RentalTermsAndConditionsModal';
import FlightDelayedModal from 'components/FlightDelay/FlightDelayedModal';
import UpdateFlightInfoModal from 'components/FlightDelay/UpdateFlightInfoModal';
import FlightConfirmedModal from 'components/FlightDelay/FlightConfirmedModal';
import CancelUpdatesModal from 'components/FlightDelay/CancelUpdatesModal';
import CurrencyConversionModal from 'components/modals/CurrencyConversionModal';
import RentalNotEligibleForModificationsModal from 'components/modals/RentalNotEligibleForModificationsModal';
import CheckInFirstNameConflictModal from 'components/modals/CheckInFirstNameConflictModal';
import ConfirmationHeader from './ConfirmationHeader';
import { propTypes } from './confirmationPropTypes';
import { loadingOverlay } from '../../utils/dom';

class Confirmation extends Component {
  static propTypes = propTypes;

  state = {
    selectedPolicyIndex: null,
    altAfterHoursPolicyContent: null,
    currentValues: null,
    updateReservationState: null,
  };

  componentDidMount() {
    const { getSupportContactInfo, countryCode, policies } = this.props;
    if (countryCode) {
      getSupportContactInfo(countryCode);
    }

    if (policies) {
      this.setAfterHoursPolicy();
    }

    // Safari uses [back-forward cache] that shows the cached page when the user clicks the back button
    // so, we need to hide the loading overlay when the page is loaded from the cache
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        loadingOverlay(false);
      }
    });

    if (sessionStorage.getItem(utils.config.reservationAssociatedToProfile)) {
      utils.localStorage.deleteSessionStorageKey(utils.config.reservationAssociatedToProfile);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      getSupportContactInfo,
      countryCode,
      isFetchingSession,
      confirmationNumber,
      isCustomPathReservation,
      retrieveReservation,
      firstName,
      lastName,
      refundObject,
      isReservationCancelled,
      prepaySelected,
    } = this.props;
    const countryCodeDidUpdate = countryCode && countryCode !== prevProps.countryCode;
    const isAuthorMode = utils.config.getIsAuthorModeEnabled();

    // Calling function 'retrieveReservation' as 'Cancellation Modal' and 'Rental Policies' Language is not being updated with current language
    // on making an retrieve reservation everything works fine.

    const selectedLanguage = JSON.parse(sessionStorage.getItem(RESERVATIONS.SELECTED_LANGUGAGE_SESSION_STORAGE));

    if (
      selectedLanguage?.prevLang !== selectedLanguage?.currLang ||
      (isReservationCancelled && prepaySelected && !refundObject?.amount && !this.state.updateReservationState)
    ) {
      Promise.all([
        retrieveReservation({
          firstName,
          lastName,
          confirmationNumber,
        }),
      ]).then(() => {
        this.setState({
          updateReservationState: !this.state.updateReservationState,
        });
      });
      // Clearing the session storage for selected langugage only if the langugage is changed/updated
      selectedLanguage?.prevLang !== selectedLanguage?.currLang &&
        sessionStorage.removeItem(RESERVATIONS.SELECTED_LANGUGAGE_SESSION_STORAGE);
    }

    if (countryCodeDidUpdate) {
      getSupportContactInfo(countryCode);
    }

    if (!isFetchingSession && prevProps.isFetchingSession) {
      // Redirect to restricted timeout page if it's a custom path reservation AWR-5859
      isCustomPathReservation && utils.timeoutRedirect.setCustomRedirect(WINDOW_OBJECT_KEYS.RESTRICTED_TIMEOUT);
    }

    // If fetching is done, there is no reservation in session and it's not author mode,
    // redirect the user to Find a Reservation page
    if (!isFetchingSession && !confirmationNumber && !isAuthorMode) {
      utils.loadingRedirect(WINDOW_OBJECT_KEYS.FIND_A_RESERVATION);
    }
  }

  setCurrentValues = (values) => {
    this.setState({ currentValues: values });
  };

  setAfterHoursPolicy = () => {
    const { policies, afterHoursReturn, afterHoursPickup, pickupLocation, returnLocation } = this.props;
    this.props
      .getAfterHoursPolicy({
        policies,
        afterHoursReturn,
        afterHoursPickup,
        pickupLocation,
        returnLocation,
      })
      .then((altAfterHoursPolicyContent) => {
        altAfterHoursPolicyContent && this.setState({ altAfterHoursPolicyContent });
      });
  };

  setIndexOfPolicyComponentViaPolicyCode = (policyCode) => () => {
    const { policies } = this.props;
    //  Finds index of the policy code
    let selectedPolicyIndex = policies.findIndex((policy) => policy.code === policyCode);
    if (selectedPolicyIndex === -1) {
      selectedPolicyIndex = null;
    }
    this.setState({
      selectedPolicyIndex,
    });
  };

  setIndexOfPolicyComponentViaIndex = (selectedPolicyIndex) => {
    this.setState({
      selectedPolicyIndex,
    });
  };

  render() {
    const {
      isFoundRental,
      breakpoint,
      reservationReady,
      isOneWayRental,
      confirmationNumber,
      ticketNumber,
      vehicleDetails,
      firstName,
      lastName,
      maskedEmail,
      policies,
      pickupLocation,
      returnLocation,
      pickupTime,
      returnTime,
      vehicleName,
      makeModelOrSimilarText,
      features,
      peopleCapacity,
      luggageCapacity,
      twoOrFourDoorText,
      images,
      vehicleCode,
      flightNumber,
      airline,
      priceSummary,
      showViewDetailsModal,
      extras,
      showLearnMoreModal,
      mileageInfo,
      prepaySelected,
      showCancelModal,
      isReservationCancelled,
      isCurrentTrip,
      afterHoursPickup,
      blockAssociateProfile,
      afterHoursReturn,
      showAHViewDetailsModal,
      additionalInformation,
      showModifyModal,
      contractDetails,
      hasLimitedInventoryExtra,
      reservationStatus,
      maskedPhoneNumber,
      billingAccount,
      selectedBundleWithExtras,
      extrasWithoutBundles,
      partner_reward_program,
      numberOfExtrasSelected,
      isThirdPartyReservation,
      handlePrepayTermsModal,
      refundObject,
      thirdPartyReservationSupportNumber,
      memberNumber,
      modifyNotAllowedReasons,
      modifyCancelResExternalLink,
      guaranteedResCardRequired,
      showGuaranteedResModal,
      cancelFee,
      coupons,
      isTourReservation,
      openTermsAndConditionsModal,
      voucherExcludedValue,
      isTourVoucherReservation,
      vehicleSearchFilters,
      vehicleSubCategory,
      openFlightDelayModal,
      openUpdateFlightInfoModal,
      openCancelUpdatesmodal,
      selectedPaymentType,
      canCancelReservation,
      openFirstNameConflictModal,
      profileFirstname,
      unauthReservation,
    } = this.props;
    const { selectedPolicyIndex } = this.state;

    if (!reservationReady) {
      return null;
    }

    const isDriveHappyDeal = GLOBAL.HAPPY_DEAL_CODE === vehicleCode;
    const shouldShowPolicyComponent = policies.length !== 0;
    const hasLimitedInventoryConfirmationPage =
      GLOBAL.HAS_LIMITED_INVENTORY_ON_CONFIRMATION_PAGE === reservationStatus || hasLimitedInventoryExtra;
    const isVehicleLimitedInventory = hasLimitedInventoryConfirmationPage && !hasLimitedInventoryExtra;

    // These 4 variables are to determine if we render the flight delay update link (within 72 hours of before pickup or 24 hours after pickup)
    const now = utils.gmi.getDateTimeObjFromTs(new Date());
    const beforePickupTime = utils.gmi.getDateTimeObjFromTs(pickupTime).subtract(72, 'hours');
    const afterPickupTime = utils.gmi.getDateTimeObjFromTs(pickupTime).add(24, 'hours');
    const displayFlightDelayLink = utils.gmi.getDateTimeObjFromTs(now).isBetween(beforePickupTime, afterPickupTime);
    const isEligible = !modifyNotAllowedReasons && !isThirdPartyReservation;

    return (
      <main className={cn('confirmation', { cancellation: isReservationCancelled })}>
        <ConfirmationHeader
          confirmationNumber={confirmationNumber}
          ticketNumber={ticketNumber}
          firstName={firstName}
          lastName={lastName}
          maskedEmail={maskedEmail}
          breakpoint={breakpoint}
          isFoundRental={isFoundRental}
          showCancelModal={showCancelModal}
          showModifyModal={showModifyModal}
          isCancelled={isReservationCancelled}
          isCurrentTrip={isCurrentTrip}
          hasLimitedInventoryConfirmationPage={hasLimitedInventoryConfirmationPage}
          isVehicleLimitedInventory={isVehicleLimitedInventory}
          vehicleName={vehicleName}
          vehicleDetails={vehicleDetails}
          isThirdPartyReservation={isThirdPartyReservation}
          handlePrepayTermsModal={handlePrepayTermsModal}
          refundObject={refundObject}
          thirdPartyReservationSupportNumber={thirdPartyReservationSupportNumber}
          modifyNotAllowedReasons={modifyNotAllowedReasons}
          modifyCancelResExternalLink={modifyCancelResExternalLink}
          guaranteedResCardRequired={guaranteedResCardRequired}
          showGuaranteedResModal={showGuaranteedResModal}
          cancelFee={cancelFee}
          isTourReservation={isTourReservation}
          returnLocation={returnLocation}
          returnTime={returnTime}
          openFlightDelayModal={openFlightDelayModal}
          displayFlightDelayLink={displayFlightDelayLink}
          isEligible={isEligible}
          isTourVoucherReservation={isTourVoucherReservation}
          contractDetails={contractDetails}
          blockAssociateProfile={blockAssociateProfile}
          openFirstNameConflictModal={openFirstNameConflictModal}
          profileFirstname={profileFirstname}
          unauthReservation={unauthReservation}
        />

        <div className='theme--dark-blue'>
          {!isCurrentTrip && (
            <ReservationDetails
              setIndexOfPolicyComponentViaPolicyCode={this.setIndexOfPolicyComponentViaPolicyCode}
              oneWayRental={isOneWayRental}
              breakpoint={breakpoint}
              pickupLocation={pickupLocation}
              returnLocation={returnLocation}
              pickupTime={pickupTime}
              returnTime={returnTime}
              fullName={`${firstName} ${lastName}`}
              maskedEmail={maskedEmail}
              vehicleName={vehicleName}
              makeModelOrSimilarText={makeModelOrSimilarText}
              transmission={utils.getTransmissionDescriptionFromFeaturesArray(features)}
              peopleCapacity={peopleCapacity}
              luggageCapacity={luggageCapacity}
              twoOrFourDoorText={twoOrFourDoorText}
              carImage={utils.getImageFromImagesObject(images)}
              isDriveHappyDeal={isDriveHappyDeal}
              flightInfo={airline && flightNumber && `${airline} ${flightNumber}`}
              priceSummary={priceSummary}
              showViewDetailsModal={showViewDetailsModal}
              extras={extras}
              showLearnMoreModal={showLearnMoreModal}
              mileageInfo={mileageInfo}
              prepaySelected={prepaySelected}
              afterHoursReturn={afterHoursReturn}
              afterHoursPickup={afterHoursPickup}
              showAHViewDetailsModal={showAHViewDetailsModal}
              additionalInformation={additionalInformation}
              isCancelled={isReservationCancelled}
              contractDetails={contractDetails}
              isVehicleLimitedInventory={isVehicleLimitedInventory}
              maskedPhoneNumber={maskedPhoneNumber}
              billingAccount={billingAccount}
              selectedBundleWithExtras={selectedBundleWithExtras}
              extrasWithoutBundles={extrasWithoutBundles}
              partner_reward_program={partner_reward_program}
              numberOfExtrasSelected={numberOfExtrasSelected}
              isThirdPartyReservation={isThirdPartyReservation}
              modifyNotAllowedReasons={modifyNotAllowedReasons}
              memberNumber={memberNumber}
              guaranteedResCardRequired={guaranteedResCardRequired}
              coupons={coupons}
              isFoundRental={isFoundRental}
              policies={policies}
              openTermsAndConditionsModal={openTermsAndConditionsModal}
              voucherExcludedValue={voucherExcludedValue}
              isTourVoucherReservation={isTourVoucherReservation}
              vehicleSearchFilters={vehicleSearchFilters}
              vehicleSubCategory={vehicleSubCategory}
              vehicleCode={vehicleCode}
              openFlightDelayModal={openFlightDelayModal}
              displayFlightDelayLink={displayFlightDelayLink}
              isEligible={isEligible}
              selectedPaymentType={selectedPaymentType}
              features={features}
              isCurrentTrip={isCurrentTrip}
              isTourReservation={isTourReservation}
              showModifyModal={showModifyModal}
              modifyCancelResExternalLink={modifyCancelResExternalLink}
              thirdPartyReservationSupportNumber={thirdPartyReservationSupportNumber}
              showCancelModal={showCancelModal}
              canCancelReservation={canCancelReservation}
            />
          )}

          {!isReservationCancelled && <RentalBranchLocation confirmation={true} isOneWayRental={isOneWayRental} />}

          <section className='confirmation__policy-section-container'>
            <div className='confirmation__policy-container row'>
              <KeyRentalFacts />
              {shouldShowPolicyComponent && (
                <PoliciesComponent
                  policies={policies}
                  selectedPolicyIndex={selectedPolicyIndex}
                  setIndexOfPolicyComponentViaIndex={this.setIndexOfPolicyComponentViaIndex}
                  backgroundArtURL={ASSETS.RENTAL_POLICIES_PLACEHOLDER}
                  backgroundArtAlt={utils.i18n('policy_background_image_alt')}
                />
              )}
            </div>
          </section>

          <Modal
            modalKey={MODAL.RENTAL_DETAILS_TAXES_FEES_MODAL}
            theme={MODAL_THEMES.WHITE}
            header={utils.i18n('rental_details_taxes_fees_modal_header')}>
            <TaxesModalContent />
          </Modal>

          <Modal
            modalKey={MODAL.RENTAL_DETAILS_AFTER_HOURS_MODAL}
            header={utils.i18n('rental_details_after_hours_modal_header')}
            theme={MODAL_THEMES.WHITE}>
            <div
              className='modal-themed__section modal-themed__gbo-content'
              dangerouslySetInnerHTML={utils.sanitize(this.state.altAfterHoursPolicyContent)}
            />
          </Modal>

          <CurrencyConversionModal />
          <PoliciesModal keyRentalFacts />
          <PrepayTermsModal />
          {/* Flight Delay Modals here */}
          <FlightDelayedModal
            openUpdateFlightInfoModal={openUpdateFlightInfoModal}
            modifyNotAllowedReasons={modifyNotAllowedReasons}
            isThirdPartyReservation={isThirdPartyReservation}
          />
          <UpdateFlightInfoModal
            openCancelUpdatesmodal={openCancelUpdatesmodal}
            savedValues={this.state.currentValues}
            updateSavedValues={this.setCurrentValues}
          />
          <FlightConfirmedModal />
          <CancelUpdatesModal openUpdateFlightInfoModal={openUpdateFlightInfoModal} />
          {!policies.length && <RentalTermsAndConditionsModal />}
          {!isReservationCancelled && !isCurrentTrip && (
            <>
              <ConfirmCancelReservationModal />
              <ModifyReservationModal />
            </>
          )}
          {isReservationCancelled && <GuaranteedReservationModal />}
          {isCurrentTrip && (
            <>
              <ReturnDirectionsModal />
              <AfterHoursReturnInstructionsModal />
              <ExtendRentalInformationModal />
              <LearnMoreAboutRoadsideAssistanceModal />
              <RentalNotEligibleForModificationsModal />
            </>
          )}
          <CheckInFirstNameConflictModal closeAssociateFnameConflictModal={true} />
        </div>
      </main>
    );
  }
}

export default Confirmation;

import React from 'react';
import utils from 'utils';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import usePostResDriverProfile from 'hooks/usePostResDriverProfile';
import { onlineCheckInStatusSelector, isEditableCheckInProfileSelector } from 'selectors/checkinSelectors';
import { isSkipTheCounterCompletedReservationSelector } from 'selectors/reservationFlowSelectors';

const InResDriverDetailsRow = ({ handleEditProfile, hideCompletedIcon }) => {
  const { license_profile, address_profile, contact_profile } = usePostResDriverProfile();

  const isSkippingTheCounter = useSelector(isSkipTheCounterCompletedReservationSelector);
  const { contactProfileComplete } = useSelector(onlineCheckInStatusSelector);
  const isProfileEditable = useSelector(isEditableCheckInProfileSelector);
  const showEditAuthUser = contactProfileComplete && isProfileEditable;
  const showEditCheckin = showEditAuthUser && !isSkippingTheCounter;

  const { country_subdivision_code, country_name } = license_profile || {};
  const issuedBySubdivision =
    !!country_subdivision_code && !utils.fieldValidation.isMasked(country_subdivision_code)
      ? `, ${country_subdivision_code}`
      : '';
  const phoneData = contact_profile?.phones?.[0];

  const { license_number } = license_profile;

  const renderDetailLine = (labelKey, value) => (
    <p className='check-in-review__paragraph'>
      {utils.i18n(labelKey)}: {value}
    </p>
  );

  return (
    <>
      <div className='post-res-trip-summary__drawer-section post-res-trip-summary__drawer-section--inres'>
        <div className='post-res__section-header'>
          <h4 className='post-res__subheading'>{utils.i18n('common_drivers_license_details')}</h4>
          {!hideCompletedIcon && (
            <span className='post-res__section-header__icon'>
              <span className='vis-hidden'>Complete</span>
            </span>
          )}
          {showEditCheckin && (
            <Button
              className='post-res__section-header__edit-cta'
              data-dtm-track='check_in|edit|driver'
              onClick={() => handleEditProfile('driver')}
              link>
              {utils.i18n('common_edit')}
            </Button>
          )}
        </div>
        {renderDetailLine('common_drivers_license_issued_by', `${country_name || ''}${issuedBySubdivision}`)}
        {license_number && renderDetailLine('common_drivers_license_number', license_number)}
        {/* {license_expiration_date && renderDetailLine('common_drivers_license_expiration_date', license_expiration_date)}
        {license_issue_date && renderDetailLine('my_profile_drivers_license_issue_date', license_issue_date)}
        {birth_date && renderDetailLine('common_date_of_birth', birth_date)} */}
      </div>

      <div className='post-res-trip-summary__drawer-section post-res-trip-summary__drawer-section--inres'>
        <div className='post-res__section-header'>
          <h4 className='post-res__subheading'>{utils.i18n('common_contact_details')}</h4>
          {!hideCompletedIcon && (
            <span className='post-res__section-header__icon'>
              <span className='vis-hidden'>Complete</span>
            </span>
          )}
          {showEditCheckin && (
            <Button
              className='post-res__section-header__edit-cta'
              data-dtm-track='check_in|edit|contact'
              onClick={() => handleEditProfile('contact')}
              link>
              {utils.i18n('common_edit')}
            </Button>
          )}
        </div>

        {renderDetailLine('common_phone_number', phoneData?.phone_number)}
        {renderDetailLine('common_address', address_profile?.street_addresses?.join(' '))}
        {renderDetailLine('common_country_of_residence', address_profile?.country_name)}
      </div>
    </>
  );
};

export default InResDriverDetailsRow;

import React from 'react';
import utils from 'utils';
import { analyticsInteraction } from 'utils/analytics';
import { ANALYTICS } from 'constants';
import { TaxesFees, Extras, TotalPrice, Location } from 'components/RentalDetails';
import BundleAddedCost from 'components/RentalDetails/RentalDetailsSharedComponents/BundleAddedCost';
import TimeAndDistance from 'components/RentalDetails/RentalDetailsSharedComponents/TimeAndDistance';
import AdditionalDrivers from 'components/RentalDetails/AdditionalDrivers';
import DatesTimes from 'components/RentalDetails/DatesTimes/DatesTimes';

import RENTAL from 'constants/rental';

const { RENTAL_VIEWS } = RENTAL;

const ConfirmModifyCommitSidebar = (props) => {
  const {
    returnTime,
    returnLocation,
    priceSummary,
    extras,
    selectedBundleWithExtras,
    extrasWithoutBundles,
    numberOfExtrasSelected,
    coupons,
    history,
    contractDetails,
    showCorporateCoverage,
    renterPaymentLines,
    billToPaymentLines
  } = props;

  const { estimated_total_vehicle_view, estimated_total_vehicle_payment } = priceSummary;

  const { vehicleLineItems, taxesAndFeeLineItems, extrasLineItems, savingsLineItems, estimatedTotalView } =
    renterPaymentLines;

  const {
    vehicleLineItems: billToVehicleLineItems,
    taxesAndFeeLineItems: billToTaxesAndFeeLineItems,
    extrasLineItems: billToExtrasLineItems,
    savingsLineItems: billToSavingsLineItems,
    estimatedTotalView: billToEstimatedTotalView,
  } = billToPaymentLines;

  const freeUpgradeApplied = utils.isFreeUpgradeAvailable(coupons);

  const handleEdit = () => () => {
    analyticsInteraction(ANALYTICS.UI_BUTTON, ANALYTICS.CONFIRM_MODIFY, ANALYTICS.DATES_AND_TIMES_EDIT);
    const path = RENTAL_VIEWS.DATE_TIME[0];

    if (path) {
      // analytics other click event was firing late and by the time redirect happens the  hashname is changed to date_time
      // to avoid racing between two events , moving redirect logic inside timeout .
      setTimeout(() => {
        history.push(path);
      }, 0);
    }
  };

  let totalExtrasSelected = numberOfExtrasSelected;
  if (totalExtrasSelected === 0 && extrasLineItems?.length > 0) {
    totalExtrasSelected = extrasLineItems.length;
  }
  return (
    <div className='modify-return-confirmation__aside review__aside'>
      <div className='review__rental-details'>
        <section className='review-rental-details rental-details'>
          <div className='rental-details__title'>
            <div className='rental-details__heading'>{utils.i18n('modified_return_details')}</div>
          </div>
          <div className='rental-details__content rental-details__content--open'>
            <div className='rental-details__card'>
              {returnTime && <DatesTimes returnTime={returnTime} handleEdit={handleEdit} isReturnReview />}
              {returnLocation?.address && <Location isReturnReview returnLocation={returnLocation} />}
            </div>

            <div className='rental-details__title'>
              <div className='rental-details__heading'>{utils.i18n('detailed_charges')}</div>
            </div>
            <div className='rental-details__card'>
              <div className='rental-details__section rental-details__vehicle'>
                <h6 className='modify-return-confirmation__sidebar-subtitle'>{utils.i18n('your_estimated_charges')}</h6>
                {vehicleLineItems && vehicleLineItems.length > 0 && (
                  <TimeAndDistance
                    chargedRate={vehicleLineItems}
                    isRental
                  />
                )}
              </div>

              <BundleAddedCost selectedBundleWithExtras={selectedBundleWithExtras} />
              <AdditionalDrivers />
              {(extras.length > 0 || extrasLineItems.length > 0) && (
                <Extras
                  numberOfExtrasSelected={totalExtrasSelected}
                  extras={extrasLineItems || extras}
                  selectedBundleWithExtras={selectedBundleWithExtras}
                  extrasWithoutBundles={extrasWithoutBundles}
                />
              )}
              <TaxesFees itemList={taxesAndFeeLineItems} />
              <TotalPrice
                estimatedTotalView={estimatedTotalView}
                freeUpgradeApplied={freeUpgradeApplied}
                isLight
                isRental
                adjustments={savingsLineItems}
              />

              {showCorporateCoverage && (
                <>
                  <div className='rental-details__section rental-details__vehicle'>
                    <h6 className='modify-return-confirmation__sidebar-subtitle'>
                      {contractDetails?.contract_name} {utils.i18n('estimated_charges')}
                    </h6>
                    {billToVehicleLineItems && billToVehicleLineItems.length > 0 && (
                      <TimeAndDistance
                        chargedRate={billToVehicleLineItems}
                        isRental
                      />
                    )}
                  </div>
                  {billToExtrasLineItems.length > 0 && (
                    <Extras
                      numberOfExtrasSelected={billToExtrasLineItems.length}
                      extras={billToExtrasLineItems}
                      selectedBundleWithExtras={selectedBundleWithExtras}
                      extrasWithoutBundles={extrasWithoutBundles}
                    />
                  )}
                  {billToTaxesAndFeeLineItems.length > 0 && <TaxesFees itemList={billToTaxesAndFeeLineItems} />}
                  <TotalPrice
                    estimatedTotalView={billToEstimatedTotalView}
                    freeUpgradeApplied={freeUpgradeApplied}
                    isLight
                    isRental
                    adjustments={billToSavingsLineItems}
                    contractDetails={contractDetails}
                  />
                </>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ConfirmModifyCommitSidebar;

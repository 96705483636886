import { connect } from 'react-redux';
import utils from 'utils';
import {
  hasExtrasSelector,
  includedExtrasAndDriversSelector,
  showBundlesSectionSelector,
  recommendedExtrasNotAddedSelector,
} from 'selectors';
import { preSelectedExtrasSelector } from 'selectors/extrasSelectors';
import { vehicleRateSelector } from 'selectors/reservationFlowSelectors';
import { MODAL } from 'constants';
import { setAllExtras } from "actions/reservation/setAllExtras";
import { extrasComplete } from "actions/reservation/extrasComplete";
import { openModal } from "actions/modal/openModal";
import Extras from './Extras';

const mapStateToProps = state => ({
  hasExtras: hasExtrasSelector(state),
  includedExtrasAndDrivers: includedExtrasAndDriversSelector(state),
  showBundles: showBundlesSectionSelector(state),
  showRecommendedExtrasAlertModal: recommendedExtrasNotAddedSelector(state).length > 0,
  preSelectedExtras: preSelectedExtrasSelector(state),
  vehicleRate: vehicleRateSelector(state),
});

const mapDispatchToProps = {
  extrasComplete,
  setAllExtras,
  openRecommendedExtrasAlertModal: () => openModal(MODAL.RECOMMENDED_EXTRAS_ALERT_MODAL),
  showConvertedTotalsModal: () => openModal(MODAL.CURRENCY_CONVERSION_DETAILS_MODAL),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(Extras));

import React from 'react';
import PropTypes from 'prop-types';
import { utils as gmiUtils } from '@ehi/global-marketing-interface';
import { i18n } from 'utils/i18n';
import { formatRemainingDays } from 'utils/date';
import { config } from 'utils/config';
import Title from 'components/Title';
import TripCard from 'components/TripCard';
import ExtendRentalInformationModal from 'components/modals/ExtendRentalInformationModal';
import AfterHoursReturnInstructionsModal from 'components/modals/AfterHoursReturnInstructionsModal';
import ReturnDirectionsModal from 'components/modals/ReturnDirectionsModal';
import LearnMoreAboutRoadsideAssistanceModal from 'components/modals/LearnMoreAboutRoadsideAssistanceModal';
import RentalNotEligibleForModificationsModal from 'components/modals/RentalNotEligibleForModificationsModal';

/**
 * CurrentTrip Component - Show the session of Current Trip in My Trips page
 *
 * @param {object} props - React Props
 * @param {object} props.currentTrips - Result of getCurrentTripDetails
 * @return {JSX} - CurrentTrip React Component
 */

const CurrentTrip = ({ currentTrips }) => (
  <div className='current-trip'>
    <Title type='h2' text={i18n('my_trips_current_title')} />
    {currentTrips.map((trip) => (
      <TripCard
        currentTripCard
        key={trip.ticket_number}
        icon='car-front'
        location={trip.return_location.name}
        confirmation_number={trip.confirmation_number}
        agreement_number={trip?.ticket_number || trip?.agreement_number}
        vehicleDetails={trip.vehicle_details}
        remainingDaysCopy={formatRemainingDays(trip.remaining_days_for_drop_off, 'return')}
        ctaLabel={i18n('trip_card_call_to_modify_cta')}
        locationDetailsID={trip.return_location.id}
        showRenterResources
        showAfterHoursCta={trip.return_location.after_hours_return}
        return_location={trip.return_location}
        dateOfRentInteraction={gmiUtils
          .getDateTimeObjFromTs(trip.return_time)
          .format(config.getDateTimeLongFormat('llll_with_comma'))}
        within24hrsReturnTime={trip.within24_hrs_of_return_time}
        ticketNumber={trip.ticket_number}
        rentalReturnConfirmed={
          Object.hasOwn(trip, 'rental_return_confirmed') ? trip.rental_return_confirmed : trip?.return_confirmed
        }
        hasConfirmModify={trip?.confirm_modify}
      />
    ))}

    <ReturnDirectionsModal />
    <AfterHoursReturnInstructionsModal />
    <ExtendRentalInformationModal />
    <LearnMoreAboutRoadsideAssistanceModal />
    <RentalNotEligibleForModificationsModal />
  </div>
);

CurrentTrip.propTypes = {
  currentTrips: PropTypes.arrayOf(PropTypes.object),
};

export default CurrentTrip;

import React, { Fragment } from 'react';
import setFieldData from 'final-form-set-field-data';
import cn from 'classnames';
import PropTypes from 'prop-types';
import utils from 'utils';
import { getIntegerNumber } from 'utils/number';

import { getFormattedContractRestrictionDates } from 'utils/reservation';
import { formatDateTimeStampAndReturnDateTimeObj } from 'utils/date';
import { objectHasOwnProperty } from 'utils/object';
import { deleteSessionStorageKey } from 'utils/localStorage';
import Form from 'components/Form/Form';
import {
  MODAL,
  GMI_SERVICE_PATHS,
  MODAL_THEMES,
  RESERVATIONS,
  LOCATIONS,
  ANALYTICS,
  WINDOW_OBJECT_KEYS,
  SESSION_STORAGE,
} from 'constants';
import { showEveryDayLowRatesBanner } from 'utils/banners';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Anchor from 'components/Anchor';
import GenericNotification from 'components/GenericNotification';
import PrerateAdditionalInfoModalContent from 'components/PrerateAdditionalInfoModalContent';
import FrequentTravelerRecoveryFeeModalContent from 'components/FrequentTravelerRecoveryFeeModalContent';
import PRPAuthAddProgramModal from 'components/modals/PRPAuthAddProgramModal';
import PRPAuthConflictModal from 'components/modals/PRPAuthConflictModal';
import LocationSearch from 'components/LocationSearch';
import DriversAge from 'components/DriversAge';
import PartnerRewardsFields from 'components/PartnerRewardsFields';
import DiscountCodeVehicleClass from 'components/DiscountCodeVehicleClass';
import GuidedFormIndicator from 'components/Form/GuidedFormIndicator';
import ServiceErrors from 'components/ServiceErrors';
import FormErrors from 'components/FormErrors';
import RemoveProductCodeModal from 'components/modals/RemoveProductCodeModal';
import VehicleClassFilterModal from 'components/modals/VehicleClassFilterModal';
import CidConflictModal from 'components/modals/CidConflictModal/CidConflictModal';
import { getSearchFilterValue } from 'utils/vehicles';
import LocationDateTimeFieldGroup from './LocationDateTimeFieldGroup';
import LocationDateTimeFormSpy from './LocationDateTimeFieldGroup/LocationDateTimeFormSpy';

const {
  EXCHANGE_TYPE_PICKUP,
  EXCHANGE_TYPE_RETURN,
  LAST_MINUTE_SPECIALS,
  PLAN_AHEAD_SPECIALS,
  IS_RES_INITIATED_COUPON_CID,
} = RESERVATIONS;

const validateReturnLocation = (value, returnLocation) =>
  value?.type || returnLocation ? null : utils.i18n('bw_return_location_submit_error');

const validate = (values) => {
  const errors = {};

  if (!values.pickupLocation) {
    errors.pickupLocation = utils.i18n('bw_pickup_location_submit_error');
  }
  if (!values.pickupDate) {
    errors.pickupDate = utils.i18n('bw_pickup_date_submit_error');
  }
  if (!values.pickupTime) {
    errors.pickupTime = utils.i18n('bw_pickup_time_submit_error');
  }
  if (!values.returnDate) {
    errors.returnDate = utils.i18n('bw_return_date_submit_error');
  }
  if (!values.returnTime) {
    errors.returnTime = utils.i18n('bw_return_time_submit_error');
  }
  if (!values.driversAge) {
    errors.driversAge = utils.i18n('bw_drivers_age_submit_error');
  }

  return errors;
};

/**
 * BookingWidget component for initiating a reservation from various parts of the site
 *
 * TODO: propTypes are out of date, these are just copied over from old propTypes file
 * @param {object}    props - React Props
 * @param {string}    props.contract_number - comes from gmi.ui.reservations.initiate.contract_number
 * @param {array}     props.coupons - comes from gmi.ui.reservations.initiate.coupons
 * @param {array}     props.couponDetails - Coupons details info from BW authoring
 * @param {function}  props.handleInitiate - GMI function for initializing a reservation based on props and values in gmi.ui.reservations.initiate
 * @param {boolean}   props.one_way_rental - comes from gmi.ui.reservations.initiate.one_way_rental
 * @param {object}    props.pickup_location - comes from gmi.ui.reservations.initiate.pickup_location
 * @param {string}    props.pickup_location_id - comes from gmi.ui.reservations.initiate.pickup_location_id
 * @param {object}    props.pickupLocationFromProps - comes from aem component authoring - prefilled location object
 * @param {string|number}  props.renter_age - comes from gmi.ui.reservations.initiate.renter_age
 * @param {object}    props.return_location - comes from gmi.ui.reservations.initiate.return_location
 * @param {string}    props.return_location_id - comes from gmi.ui.reservations.initiate.return_location_id
 * @param {function}  props.setCoupon - store coupon in gmi.ui.reservations.initiate
 * @param {function}  props.setContractNumber - store contract in gmi.ui.reservations.initiate
 * @param {function}  props.setLocation - store location in gmi.ui.reservations.initiate, by exchange type
 * @param {boolean}   props.isCollapsed - identifies if BW should render in collapsed state
 * @param {object}    props.breakpoint - identifies mobile/tablet/desktop breakpoint status
 * @param {object}    props.contractDetails - Authorable Contract (CID) details object
 * @param {object}    props.partnerRewardProgram - Authorable PRP object loaded for PRP pages
 * @param {boolean}   props.enablePartnerRewards - Authorable PRP flag to show/hide PRP fields
 * @param {function}  props.setPartnerRewardProgram - Action to set PRP code and name to GMI initiate request
 * @param {function}  props.setPartnerRewardProgramMemberId - Action to set PRP member ID to GMI initiate request
 * @param {boolean}   props.isTrueModify - identifies True Modify status:
 * @param {boolean}   props.isDeeplink - flag to check if it is a deeplink
 * @param {object}    props.profileData - object with retrieved data from user profile state
 * @param {number}    props.profileData.age - user profile age
 * @param {boolean}   props.disableCouponCode - flag set by authoring to disable Coupon Code field
 * @param {boolean}   props.contractCollapsed - flag set by authoring to collapse/expand Discount Codes fields by default
 * @param {boolean}   props.collapsed - flag set by authoring to collapse the entire Booking Widget
 * @param {string}    props.bundleProfile - default bundle authored from AEM
 * @param {function}  props.setBundleProfile - GMI function to set a bundle to the initiate reservation
 * @param {boolean}   props.returnLocationCollapsed - flag set by authoring to collapse/expand the return location input
 * @return {JSX}      BookingWidget component
 */

class BookingWidget extends React.Component {
  constructor(props) {
    super(props);

    // This flag checks if the BW have CID or Coupon code from AEM authoring
    const isDiscountCodeAuthored =
      !!props.contractDetails?.contractDetails ||
      utils.gmi.isArrayNotEmpty(props.couponDetails?.couponDetails) ||
      props.discountCodesExpanded;

    this.state = {
      isCollapsed: props.collapsed,
      isDiscountCollapsed: isDiscountCodeAuthored || props.isTrueModify ? false : props.contractCollapsed,
      isOneWay: props.one_way_rental || !props.returnLocationCollapsed,
      exchangeType: null,
      disableGuidedFlow: false,
      initialValues: null,
      hasBeenFocused: false,
      isSixColumnGrid: false,
      hasClearedSession: false,
      restrictedDate: false,
      isLmOrPlanAheadDiscountRemoved: false,
      pickupLocationRemoved: false,
      returnLocationRemoved: false,
      initialSearchByVehicles: getSearchFilterValue(props.vehicleSearchFilters),
      reservationSessionLDTUpdated: false,
      isDriverAgeUpdated: false,
    };

    // If authors configured component with predefined bundle profile
    if (props.bundleProfile) {
      props.setBundleProfile(props.bundleProfile);
    }

    this._returnLocation = null; // Stores return location for toggle rehydration

    const authoredContractSubType = props.contractDetails?.contractDetails?.contractSubType;
    this.isLastMinuteSpecials = authoredContractSubType === LAST_MINUTE_SPECIALS;
    this.isPlanAheadSpecials = authoredContractSubType === PLAN_AHEAD_SPECIALS;
    this.authoredContractRestrictions = props.contractDetails?.contractDetails?.restrictions;
    this.authoredFormattedContractRestrictions =
      this.authoredContractRestrictions &&
      getFormattedContractRestrictionDates(this.authoredContractRestrictions, this.isPlanAheadSpecials);
  }

  componentDidUpdate(prevProps) {
    const {
      rentalStateConfirmed,
      removeInitiateResObject,
      pickup_location_id,
      profileData,
      partnerRewardProgram,
      setPartnerRewardProgram,
      contractDetails,
      setContractNumber,
      isTrueModify,
      productCode,
      showProductCode,
      vehicleSearchFilters,
      pickup_location,
      reservationObjectInitialValues,
      isInResBookingWidget,
      driversAgeInitialValue,
      uiInitiateRequestalues,
    } = this.props;

    const isAtStartOfStandAloneBookingWidgetPage = utils.dom.isAtStartOfStandAloneBookingWidgetPage();
    const { hasClearedSession, initialValues, reservationSessionLDTUpdated } = this.state;
    const driversAgeIntegerValue = getIntegerNumber(initialValues?.driversAge);
    const skipAnalyticsForPickAndReturnDateTime = sessionStorage.getItem(
      ANALYTICS.SKIP_ANALYTICS_FOR_SAVED_RESERVATION_SESSION_LDT_VALUES
    );

    //  This checks if we should clear the initiate rez object, so the BW isn't pre populated when coming back from the confirmation page
    if (
      !isTrueModify &&
      rentalStateConfirmed &&
      pickup_location_id &&
      !hasClearedSession &&
      isAtStartOfStandAloneBookingWidgetPage
    ) {
      this.setState({ hasClearedSession: true }, () => {
        removeInitiateResObject();
      });
    }

    // check for reservation stored in session and then set initial pre fill LDT information
    if (
      !isInResBookingWidget &&
      !isTrueModify &&
      !utils.gmi.isObjectEmpty(reservationObjectInitialValues) &&
      !reservationSessionLDTUpdated
    ) {
      this.applyReservation(reservationObjectInitialValues, initialValues);
      this.setState({
        reservationSessionLDTUpdated: !reservationSessionLDTUpdated,
        displayingPreservedSessionData:
          !isInResBookingWidget && !isTrueModify && reservationObjectInitialValues && !reservationSessionLDTUpdated,
      });
      sessionStorage.setItem(ANALYTICS.SKIP_ANALYTICS_FOR_SAVED_RESERVATION_SESSION_LDT_VALUES, true);
    }

    // clearing sessionStorage values once the initiateRequestValues for pickup_time and return_time got updated.
    if (
      !isTrueModify &&
      reservationSessionLDTUpdated &&
      uiInitiateRequestalues.pickup_time &&
      uiInitiateRequestalues.return_time &&
      skipAnalyticsForPickAndReturnDateTime
    ) {
      sessionStorage.removeItem(ANALYTICS.SKIP_ANALYTICS_FOR_SAVED_RESERVATION_SESSION_LDT_VALUES);
    }

    if (vehicleSearchFilters?.selectedValues !== prevProps.vehicleSearchFilters?.selectedValues) {
      const initialSearchByVehicles = pickup_location
        ? getSearchFilterValue(vehicleSearchFilters)
        : utils.i18n('all_vehicles');
      this.setState({
        initialSearchByVehicles,
      });
    }
    const newInitialValues = { ...initialValues };
    let changedInitialValues = false;

    if (profileData?.age && initialValues?.driversAge !== profileData?.age) {
      changedInitialValues = true;
      newInitialValues.driversAge = profileData?.age;
    }

    if (
      !profileData?.age &&
      !isInResBookingWidget &&
      !isTrueModify &&
      driversAgeIntegerValue > 0 &&
      initialValues?.driversAge &&
      reservationObjectInitialValues.renter_age &&
      driversAgeInitialValue &&
      reservationObjectInitialValues.renter_age <= driversAgeInitialValue &&
      initialValues?.driversAge !== reservationObjectInitialValues.renter_age
    ) {
      changedInitialValues = true;
      newInitialValues.driversAge = reservationObjectInitialValues.renter_age;
    }

    // When user logs in and we have PRP data we check users profile PRP:
    if (prevProps.profileData !== profileData && partnerRewardProgram) {
      changedInitialValues = true;
      if (utils.profile.profileProgramMatchPRP(profileData, partnerRewardProgram)) {
        // - If users program is the same as the page, we unset PRP and contract fields
        delete newInitialValues.contractNumber;
        delete newInitialValues.partnerRewardsProgram;
        delete newInitialValues.partnerRewardsCode;
      } else {
        // - If there is no profile or the profile do not match the page, we set the fields
        newInitialValues.contractNumber = contractDetails?.contractDetails?.contractNumber || '';
        newInitialValues.partnerRewardsProgram = partnerRewardProgram.name;
        newInitialValues.partnerRewardsCode = partnerRewardProgram.code;
      }
    }

    if (changedInitialValues) {
      this.setState({ initialValues: newInitialValues }, () => {
        setPartnerRewardProgram(
          newInitialValues.partnerRewardProgram || {
            code: newInitialValues?.partnerRewardsCode,
            name: newInitialValues?.partnerRewardsProgram,
          }
        );
        setContractNumber(newInitialValues.contractNumber);
      });
    }

    const isProductCodeUpdated = prevProps?.productCode !== productCode;
    const isShowProductCodeUpdated = prevProps?.showProductCode !== showProductCode;

    if ((isProductCodeUpdated && !!productCode) || (isShowProductCodeUpdated && showProductCode)) {
      this.toggleDiscountCollapsed(false);
    }
  }

  componentDidMount() {
    const {
      pickupLocationFromProps,
      pickup_location,
      reservationObject,
      setContractNumber,
      setCoupon,
      setLocation,
      setPartnerRewardProgram,
      driversAgeInitialValue,
      contractDetails,
      couponDetails,
      partnerRewardProgram,
      profileData,
      productCode,
      showProductCode,
      clearInitialVehicleClassFilterData,
    } = this.props;

    // Safari uses [back-forward cache] that shows the cached page when the user clicks the back button
    // so, we need to hide the loading overlay when the page is loaded from the cache
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        clearInitialVehicleClassFilterData();
      }
    });

    if (productCode || showProductCode) {
      this.toggleDiscountCollapsed(false);
    }

    const profileProgramDoesntMatchComponentProgram = !utils.profile.profileProgramMatchPRP(
      profileData,
      partnerRewardProgram
    );

    const isSixColumnGrid = utils.dom.isContainedInSixColumnGrid(this.clickOutsideRef.current);
    this.setState({ isSixColumnGrid });

    const haveLocationFromProps = !utils.gmi.isObjectEmpty(pickupLocationFromProps) && !pickup_location;
    let initialValues = { driversAge: driversAgeInitialValue };

    if (haveLocationFromProps) {
      // If authors configured component with preselected location and user has not changed it
      // setLocation action has to be dispatch to update store
      initialValues = {
        ...initialValues,
        pickupLocation: pickupLocationFromProps,
        returnLocation: pickupLocationFromProps,
      };
    }

    /**
     * IF
     *  - contract details are authored on the booking widget
     *  - the user is not authenticated OR the profile PRP does not match a existing program in the BW
     * THEN use the authored contract as the initialValue
     */
    if (contractDetails?.contractDetails && profileProgramDoesntMatchComponentProgram) {
      initialValues.contractNumber = contractDetails.contractDetails.contractNumber;
    }

    if (couponDetails?.couponDetails?.[0]) {
      initialValues.couponCode = couponDetails?.couponDetails?.[0]?.description;
    }

    if (productCode) {
      initialValues.productCode = productCode;
    }

    /**
     * IF
     *  - PRP data is authored on the booking widget
     *  - user is unauthenticted or profile PRP do not match the program authored to the booking widget
     * THEN add the PRP fields/data to BW initial values
     */
    if (partnerRewardProgram && profileProgramDoesntMatchComponentProgram) {
      initialValues = {
        ...initialValues,
        partnerRewardsProgram: partnerRewardProgram.name,
        partnerRewardsCode: partnerRewardProgram.code,
      };
    }

    // check for reservation prop, or abanadoned res, or only apply the initial values declared above
    if (reservationObject) {
      this.applyReservation(reservationObject, initialValues);
    } else if (this.props.prefilled) {
      this.props.setAbandonedRes().then((reservation) => {
        this.applyReservation(reservation, initialValues);
      });
    } else {
      this.setState({ initialValues }, () => {
        if (haveLocationFromProps) {
          setLocation(pickupLocationFromProps);
          this.fetchDefaultAgeRentals(pickupLocationFromProps);
        }
        if (initialValues.contractNumber) {
          setContractNumber(initialValues.contractNumber);
        }
        if (initialValues.couponCode) {
          setCoupon(couponDetails?.couponDetails?.[0]?.code);
        }
        if (initialValues.partnerRewardsCode) {
          setPartnerRewardProgram(partnerRewardProgram);
        }
      });
    }
  }

  toggleDiscountCollapsed = (toggle) => {
    this.setState({ isDiscountCollapsed: toggle });
  };

  getSplitTime = (pickup_return_time) => pickup_return_time?.split('T') || [];

  applyReservation = (reservation = {}, initialValuesPassedIn = {}) => {
    const foundReservation = !utils.gmi.isObjectEmpty(reservation);
    const {
      getAgeRangeDefaults,
      isTrueModify,
      productCode,
      showProductCode,
      setCoupon,
      setContractNumber,
      initiateRequestDetails,
      reservationInitiateError,
      partnerRewardProgram,
      couponDetails,
      isBranchWidget,
      didProgressBarLocationInitiate,
      setIsGmiUIOneWayRental,
    } = this.props;
    const {
      contract_number,
      one_way_rental,
      pickup_location,
      pickup_time,
      return_location,
      return_time,
      renter_age,
      coupons,
      contract_details,
    } = reservation;

    let initialValues = { ...initialValuesPassedIn };

    // Contract number should be blank if it is the alamo insiders loyalty contract
    const shouldPopulateContractNumber = contract_number && !contract_details?.default_loyalty_contract;
    const contactNumberInitialValue = shouldPopulateContractNumber ? contract_number : '';
    const initialContractNumberBasedOnPASOrLMS =
      objectHasOwnProperty(reservation, 'isCurrentPathSameasPreviousPath') &&
      !reservation.isCurrentPathSameasPreviousPath
        ? initialValues?.contractNumber
        : contactNumberInitialValue;

    // must wait for async age range response, or resolve false
    Promise.resolve(
      !!(foundReservation && pickup_location.address) && getAgeRangeDefaults(pickup_location.address.country_code)
    ).then(() => {
      if (foundReservation) {
        // pickup/return datetime is different than from GBO, should update with GBO info
        // reset gmi > ui, to match what's in gbo
        const [pickupDate, pickupTime] =
          isTrueModify && reservationInitiateError.length > 0 && pickup_time !== initiateRequestDetails?.pickup_time
            ? this.getSplitTime(initiateRequestDetails?.pickup_time)
            : this.getSplitTime(pickup_time);
        const [returnDate, returnTime] =
          isTrueModify && reservationInitiateError.length > 0 && return_time !== initiateRequestDetails?.return_time
            ? this.getSplitTime(initiateRequestDetails?.return_time)
            : this.getSplitTime(return_time);

        // Check if contract is Last Mintue Special or Plan Ahead Special
        const initialValuesIsLmOrPlanAheadSpecials =
          contract_details && contract_details?.contract_sub_type === (LAST_MINUTE_SPECIALS || PLAN_AHEAD_SPECIALS);

        // Use for inRes Booking Widget... FE get the information from initialValues state
        const initialContractRestriction = contract_details && contract_details?.restrictions;
        const initialValueFormattedContractRestrictions =
          initialContractRestriction &&
          getFormattedContractRestrictionDates(
            initialContractRestriction,
            contract_details?.contract_sub_type === PLAN_AHEAD_SPECIALS
          );

        // didProgressBarLocationInitiate will be set to false on initiate reservation failure and then we use gboPickuplocation
        let pickupLocation = pickup_location;
        let returnLocation = return_location;

        // reset gmi>ui to match with gbo pick up and return location;

        if (!didProgressBarLocationInitiate && Object.hasOwn(initiateRequestDetails, 'pickup_location')) {
          pickupLocation =
            initiateRequestDetails?.pickup_location?.name !== pickupLocation?.name
              ? initiateRequestDetails?.pickup_location
              : pickupLocation;
          returnLocation =
            initiateRequestDetails?.return_location?.name !== pickupLocation?.name
              ? initiateRequestDetails?.return_location
              : returnLocation;
        }

        // If reservartion widget is branch, replace reservation pickup location with branch location
        // and keep the rest of the reservation session object
        if (isBranchWidget) {
          if (initialValues?.pickupLocation && pickup_location) {
            pickupLocation = initialValues?.pickupLocation;
          }
        }

        initialValues = {
          pickupLocation,
          pickupDate,
          pickupTime,
          returnLocation,
          returnDate,
          returnTime,
          driversAge: renter_age,
          contractNumber: initialContractNumberBasedOnPASOrLMS,
          contractName: contract_details?.contract_name,
          contractSubType: contract_details?.contract_sub_type,
          contractIsLmOrPlanAheadSpecials: initialValuesIsLmOrPlanAheadSpecials,
          contractRestrictions: contract_details?.restrictions,
          contractDetails: contract_details,
          couponCode: coupons?.[0]?.description || couponDetails?.couponDetails?.[0]?.description || coupons?.[0],
          formattedContractRestrictions: initialValueFormattedContractRestrictions,
          partnerRewardsProgram: partnerRewardProgram?.name,
          partnerRewardsCode: partnerRewardProgram?.code,
        };
      }

      this.setState(
        ({ isOneWay }) => ({
          disableGuidedFlow: foundReservation,
          initialValues,
          isOneWay: one_way_rental || isOneWay,
          isDiscountCollapsed: !(
            contract_number ||
            coupons?.[0] ||
            initialValues?.couponCode ||
            isTrueModify ||
            productCode ||
            showProductCode ||
            initialContractNumberBasedOnPASOrLMS
          ),
          isExpanded: contract_number,
        }),
        () => {
          // Set values on gmi.ui.reservations.initiate
          initialValues.contractNumber && setContractNumber(initialValues.contractNumber);

          initialValues.couponCode &&
            setCoupon(coupons?.[0]?.code || couponDetails?.couponDetails?.[0]?.code || coupons?.[0]);

          initialValues.pickupDate && this.handleSetDate(EXCHANGE_TYPE_PICKUP)(initialValues.pickupDate);

          initialValues.pickupTime && this.handleSetTime(EXCHANGE_TYPE_PICKUP)(initialValues.pickupTime);

          initialValues.returnDate && this.handleSetDate(EXCHANGE_TYPE_RETURN)(initialValues.returnDate);

          initialValues.returnTime && this.handleSetTime(EXCHANGE_TYPE_RETURN)(initialValues.returnTime);

          initialValues.pickupLocation && this.handleSetLocation(EXCHANGE_TYPE_PICKUP)(initialValues.pickupLocation);

          one_way_rental && setIsGmiUIOneWayRental(initialValues.one_way_rental);
          one_way_rental &&
            initialValues.returnLocation &&
            this.handleSetLocation(EXCHANGE_TYPE_RETURN)(initialValues.returnLocation);
        }
      );
    });
  };

  // Show everday low rate banner according to the CID restrictions
  shouldDisplayEveryDayLowRatesBanner = (type, date) => {
    const { pickupDate, returnDate } = this.props;
    const { initialValues } = this.state;

    const restrictedDate = showEveryDayLowRatesBanner(
      type,
      date,
      pickupDate,
      returnDate,
      this.authoredFormattedContractRestrictions || initialValues?.formattedContractRestrictions
    );

    this.setState({ restrictedDate });
  };

  handleSetDate = (exchangeType) => (date) => {
    const { clearDate, pickupDate, returnDate, setDate } = this.props;
    const { initialValues } = this.state;
    // The time stamp is already coming in as an abstracted (timeless) string, this transformation is just to
    // format that string correctly... `Tue May 12 2020 00:00:00 GMT+0200 (Central European Summer Time)` => `2020-05-12`
    const timeStamp = utils.gmi.getDateTimeObjFromTs(date).format().slice(0, 10);

    let expectedExchangeType = exchangeType;

    if (exchangeType === EXCHANGE_TYPE_PICKUP && utils.gmi.getDateTimeObjFromTs(returnDate).isBefore(timeStamp)) {
      clearDate(EXCHANGE_TYPE_RETURN);
    }

    if (exchangeType === EXCHANGE_TYPE_RETURN && utils.gmi.getDateTimeObjFromTs(pickupDate).isAfter(timeStamp)) {
      expectedExchangeType = EXCHANGE_TYPE_PICKUP;
      returnDate && clearDate(EXCHANGE_TYPE_RETURN);
    }

    (this.authoredContractRestrictions || initialValues?.contractRestrictions) &&
      this.shouldDisplayEveryDayLowRatesBanner(exchangeType, formatDateTimeStampAndReturnDateTimeObj(date));

    setDate(timeStamp, expectedExchangeType);
  };

  handleSetTime = (exchangeType) => (time) => {
    this.props.setTime(time, exchangeType);
  };

  handleFormClick = () => {
    // The first time the booking widget receives a click and is in focus, we want to scroll it to the top with a bit of
    // room for the Title component that we _assume_ will be authored there.
    // NOTE: This is relying on the clickoutside ref.
    // NOTE2: This changed from onFocus to onClick as the focus event is triggered before other click events and this was
    // causing some unwanted side effects.
    const { breakpoint } = this.props;
    const { hasBeenFocused } = this.state;
    if (!hasBeenFocused && breakpoint.isDesktop) {
      const bwScrollingPosition = this.clickOutsideRef.current.offsetTop - 80; // 80 is a magic number
      utils.dom.scrollPage(0, bwScrollingPosition);
      this.setState({ hasBeenFocused: true });
    }
    this.handleDisabledGuidedFlowState();
  };

  /**
   * handleDisabledGuidedFlowState - enables the guided flow if it is clicked outside the booking widget
   */

  handleDisabledGuidedFlowState = () => {
    const { disableGuidedFlow } = this.state;
    if (disableGuidedFlow) {
      this.setState({ disableGuidedFlow: false });
    }
  };

  /**
   * onToggleOneWay - Toggles one way and updates return location object in store
   */
  onToggleOneWay = () => {
    const { setLocation, unsetLocation, pickup_location, return_location, setIsGmiUIOneWayRental } = this.props;
    // When toggling isOneWay we need to unset return location which might've been set to match pickup earlier
    unsetLocation(EXCHANGE_TYPE_RETURN);
    // When removing different return location have to reset return location in store to match pickup
    if (this.state.isOneWay && pickup_location) {
      this._returnLocation = return_location; // store the return location
      setLocation(pickup_location, EXCHANGE_TYPE_RETURN);
    }
    if (!this.state.isOneWay && this._returnLocation) {
      setLocation(this._returnLocation, EXCHANGE_TYPE_RETURN);
    }
    setIsGmiUIOneWayRental(!this.state.isOneWay);
    this.setState({ isOneWay: !this.state.isOneWay, isCollapsed: false });
  };

  /**
   * handleUnsetLocation - Removes provided location from global store
   *
   * @param {String} exchangeType Type of location being removed
   */
  handleUnsetLocation =
    (exchangeType = EXCHANGE_TYPE_PICKUP) =>
    () => {
      const { unsetLocation } = this.props;
      const { isOneWay } = this.state;
      if (isOneWay && exchangeType === EXCHANGE_TYPE_RETURN) {
        this._returnLocation = null;
      }
      if (!isOneWay) {
        unsetLocation(EXCHANGE_TYPE_RETURN);
      }
      unsetLocation(exchangeType);

      exchangeType === EXCHANGE_TYPE_PICKUP && this.setState({ pickupLocationRemoved: true });
      exchangeType === EXCHANGE_TYPE_RETURN && this.setState({ returnLocationRemoved: true });
      this.handleDisabledGuidedFlowState();
    };

  /**
   * handleSetLocation - Updates redux store with provided location object - used both for pickup and return locations
   *
   * @param {String} exchangeType pickup or return location - defaults to pickup for round trip case
   * @param {Object} location Location object from GBO
   */
  handleSetLocation =
    (exchangeType = EXCHANGE_TYPE_PICKUP) =>
    (location) => {
      const { setLocation, one_way_rental, setIsGmiUIOneWayRental } = this.props;
      const { isOneWay } = this.state;
      if (isOneWay && exchangeType === EXCHANGE_TYPE_RETURN) {
        this._returnLocation = location;
      }
      if (!isOneWay) {
        this.setState(
          {
            isDriverAgeUpdated: !this.state.isDriverAgeUpdated,
          },
          () => setLocation(location)
        );
        this.fetchDefaultAgeRentals(location);
      } else {
        setLocation(location, exchangeType);
        // set one_way_rental true , if isOneWay state is true boolean value and one_way_rental is undefined /false;
        if (!one_way_rental && isOneWay) {
          setIsGmiUIOneWayRental(isOneWay);
        }
      }
      this.setState({ isCollapsed: false });
    };

  fetchDefaultAgeRentals = (location) => {
    const { reservationObjectInitialValues, isTrueModify, isInResBookingWidget } = this.props;
    const { initialValues, reservationSessionLDTUpdated } = this.state;
    // set ageRental defaults value on the booking widget when user changes the location
    location?.address &&
      Promise.resolve(this.props.getAgeRangeDefaultsByLocation(location.address.country_code)).then((res) => {
        if (!res.messages) {
          // user selected age
          let userSelection = initialValues?.driversAge;
          let userSelectedAge = sessionStorage.getItem(SESSION_STORAGE.USER_AGE_SELECTED);

          if (
            !isTrueModify &&
            !isInResBookingWidget &&
            reservationObjectInitialValues?.renter_age &&
            reservationSessionLDTUpdated
          ) {
            userSelection = reservationObjectInitialValues?.renter_age;
            userSelectedAge = true;
          }
          if (!reservationObjectInitialValues?.renter_age && !reservationSessionLDTUpdated) {
            deleteSessionStorageKey(SESSION_STORAGE.USER_AGE_SELECTED);
            userSelectedAge = false;
          }
          if (isInResBookingWidget) {
            userSelectedAge = false;
          }

          // each location has a default selected age
          const defaultSelection = res.find((age) => age.selected);
          // sometimes user age is not present in location response, so just checking
          const userSelectionExists = res.some((age) => age?.value === userSelection);

          // if user selected age exists we use it, otherwise we use the default age from location
          const selectedAge = userSelectionExists && userSelectedAge ? userSelection : defaultSelection?.value;

          if (selectedAge) {
            const newInitialValues = { ...initialValues };
            newInitialValues.driversAge = selectedAge;
            newInitialValues.pickupLocation = location;
            this.setState({
              initialValues: { ...newInitialValues },
              isDriverAgeUpdated: true,
            });
          }
        }
      });
  };

  handleClickOutside = () => {
    this.setState({ disableGuidedFlow: true });
  };

  /**
   * Handle Discount Code Expand Callback
   */
  handleDiscountCodeExpand = (isExpanded) => {
    this.setState({ isDiscountCollapsed: !isExpanded });
  };

  /**
   * Handle LMS or Plan Ahead Discount is removed from LMS/PAS page
   */
  handleLmOrPlanAheadDiscountRemoved = (isRemoved) => {
    this.setState({ isLmOrPlanAheadDiscountRemoved: isRemoved });
  };

  /**
   * renderCollapsedSection - Renders fields that might be collapsed on initial render
   */
  renderCollapsedSection = (submitFailed) => {
    const {
      pickup_location,
      pickup_location_id,
      return_location,
      return_location_id,
      setCoupon,
      contractDetails,
      setContractNumber,
      pickupDate,
      pickupDateValue,
      pickupEligibility,
      pickupTime,
      returnDate,
      returnDateValue,
      returnEligibility,
      returnTime,
      enablePartnerRewards,
      partnerRewardProgram,
      setPartnerRewardProgramMemberId,
      isTrueModify,
      profileData,
      disableCouponCode,
      productCode,
      showProductCode,
      openRemoveProductCodeModal,
      isDeeplink,
      breakpoint,
      one_way_rental,
      showVehicleClassFilterModal,
    } = this.props;
    const {
      isOneWay,
      isDiscountCollapsed,
      isSixColumnGrid,
      initialValues,
      isLmOrPlanAheadDiscountRemoved,
      pickupLocationRemoved,
      returnLocationRemoved,
      initialSearchByVehicles,
      displayingPreservedSessionData,
      isDriverAgeUpdated,
    } = this.state;
    const pickUpLocationID = pickup_location?.type === LOCATIONS.TYPE_BRANCH ? pickup_location_id : null;
    const returnLocationID = return_location?.type === LOCATIONS.TYPE_BRANCH ? return_location_id : null;
    const renderPRPFields =
      enablePartnerRewards && !utils.profile.profileProgramMatchPRP(profileData, partnerRewardProgram);
    const readOnlyContractField =
      contractDetails?.contractDetails?.allowAccountRemovalIndicator === false || isTrueModify;
    // If window minus component size is larger than 600 and the component is smaller than 1250, it means that the BW should be handled as deals page style
    const isBWOnDealsPage =
      window.innerWidth - (this.clickOutsideRef?.current?.offsetWidth ?? 0) > 600 &&
      this.clickOutsideRef?.current?.offsetWidth < 1250;

    return (
      <Fragment>
        {isOneWay && (
          <div className='fieldset fieldset--location-search'>
            <LocationSearch
              id='returnLocation'
              formId='formWrapper'
              name='returnLocation'
              type={LOCATIONS.LOCATION_SEARCH_TYPES.BOOKING_WIDGET}
              label={utils.i18n('location_search_return_placeholder')}
              setLocation={this.handleSetLocation(EXCHANGE_TYPE_RETURN)}
              unsetLocation={this.handleUnsetLocation(EXCHANGE_TYPE_RETURN)}
              toggleOneWay={this.onToggleOneWay}
              isOneWay={isOneWay}
              selectedLocation={return_location}
              guided
              validate={(value) => validateReturnLocation(value, return_location)}
            />
          </div>
        )}

        <div
          className={cn('booking-widget__input-group', {
            'booking-widget__input-group--deals': isBWOnDealsPage,
          })}
        >
          <div className='booking-widget__input-group-left'>
            <LocationDateTimeFieldGroup
              pickup_location_id={pickUpLocationID || (!pickupLocationRemoved ? initialValues?.pickupLocation?.id : '')}
              return_location_id={returnLocationID || (!returnLocationRemoved ? initialValues?.returnLocation?.id : '')}
              pickupDate={pickupDate}
              pickupDateValue={pickupDateValue}
              pickupEligibility={pickupEligibility}
              pickupTime={pickupTime}
              returnDate={returnDate}
              returnDateValue={returnDateValue}
              returnEligibility={returnEligibility}
              returnTime={returnTime}
              onDateChangeReturn={this.handleSetDate(EXCHANGE_TYPE_RETURN)}
              onTimeChangeReturn={this.handleSetTime(EXCHANGE_TYPE_RETURN)}
              onDateChangePickUp={this.handleSetDate(EXCHANGE_TYPE_PICKUP)}
              onTimeChangePickUp={this.handleSetTime(EXCHANGE_TYPE_PICKUP)}
              isSixColumnGrid={isSixColumnGrid}
              guided
              contractRestrictions={
                (this.isLastMinuteSpecials ||
                  this.isPlanAheadSpecials ||
                  initialValues?.contractIsLmOrPlanAheadSpecials) &&
                !isLmOrPlanAheadDiscountRemoved &&
                (this.authoredFormattedContractRestrictions || initialValues?.formattedContractRestrictions)
              }
            />

            {!profileData?.age && (
              <div className='fieldset fieldset--drivers-age'>
                <DriversAge
                  readOnly={isTrueModify}
                  value={initialValues?.driversAge}
                  tooltip={isTrueModify && utils.i18n('true_modify_booking_widget_driver_age_tooltip')}
                  isDriverAgeUpdated={isDriverAgeUpdated}
                  driversAge={initialValues?.driversAge}
                />
              </div>
            )}

            {renderPRPFields && (
              <div className='fieldset fieldset-group fieldset--partner-rewards'>
                <PartnerRewardsFields
                  setPartnerMemberNumber={setPartnerRewardProgramMemberId}
                  submitFailed={submitFailed}
                />
              </div>
            )}
            <div
              className={cn('fieldset fieldset--discount-code fieldset-group', {
                'fieldset--discount-code-coupon': !disableCouponCode,
                'fieldset--product-code-coupon': !!productCode || showProductCode,
              })}
            >
              <DiscountCodeVehicleClass
                openRemoveProductCodeModal={openRemoveProductCodeModal}
                isDiscountCollapsed={isDiscountCollapsed}
                setContract={setContractNumber}
                setCoupon={setCoupon}
                initialProductCode={productCode}
                initialCouponCode={initialValues?.couponCode}
                initialContractCode={initialValues?.contractNumber || initialValues?.contractDetails?.contract_number}
                initialContractName={initialValues?.contractName}
                initialIsLmOrPlanAheadSpecials={initialValues?.contractIsLmOrPlanAheadSpecials}
                showProductCode={showProductCode}
                onExpand={this.handleDiscountCodeExpand}
                onLmOrPlanAheadDiscountRemoval={this.handleLmOrPlanAheadDiscountRemoved}
                readOnlyContract={readOnlyContractField}
                isLmsOrPlanAhedSpecials={this.isLastMinuteSpecials || this.isPlanAheadSpecials}
                disableCouponCode={disableCouponCode}
                isSixColumnGrid={isSixColumnGrid}
                isPartnerRewards={enablePartnerRewards}
                authoredContract={contractDetails}
                isDeeplink={isDeeplink}
                isTrueModify={isTrueModify}
                breakpoint={breakpoint}
                disableVCFilter={one_way_rental}
                pickupLocationId={pickup_location_id}
                showVehicleClassFilterModal={showVehicleClassFilterModal}
                initialSearchByVehicles={initialSearchByVehicles}
                displayingPreservedSessionData={displayingPreservedSessionData}
              />
              {breakpoint.isTablet && (
                <div className='booking-widget__input-group-right'>
                  <Button className='button-go' type='submit'>
                    {utils.i18n('bw_submit_label')}
                  </Button>
                </div>
              )}
            </div>
          </div>
          {(!breakpoint.isTablet || isBWOnDealsPage) && (
            <div className='booking-widget__input-group-right'>
              <Button className='button-go' type='submit'>
                {utils.i18n('bw_submit_label')}
              </Button>
            </div>
          )}
        </div>
      </Fragment>
    );
  };

  handleSubmit = () => {
    const {
      handleInitiate,
      partnerRewardProgram,
      showFrequentTravelerRecoveryFeeModal,
      profileData,
      showUpdateProfileWithPRPModal,
      showAuthConflictPRPModal,
      setDiscountCodeExpanded,
      pickup_location,
      return_location,
      uiInitiateRequestalues,
    } = this.props;

    if (partnerRewardProgram) {
      if (profileData) {
        if (!profileData?.partnerRewardsProgram) {
          // If authenticated user do not have a PRP attached to the profile,
          // open the modal for Fees details and add to profile option.
          showUpdateProfileWithPRPModal();
        } else if (profileData?.partnerRewardsProgram?.code !== partnerRewardProgram?.code) {
          // If authenticated user have a preferred PRP but the code does not match,
          // open the modal to choosen between updating profile or continue with existing
          showAuthConflictPRPModal();
        } else {
          // If authenticated user have a preferred PRP and it's the same, just initiate the reservation
          handleInitiate(this.props);
        }
      } else if (partnerRewardProgram.surcharge) {
        showFrequentTravelerRecoveryFeeModal();
      } else {
        // Initiate directly if user not authenticated and program have no surcharge
        handleInitiate(this.props);
      }
    } else {
      handleInitiate(this.props);
    }

    // Storing the coupon/contract name which will be used to hide coupon/cid fields if not selected for auth user
    if (
      uiInitiateRequestalues?.coupons ||
      uiInitiateRequestalues?.contract_name ||
      uiInitiateRequestalues?.contract_number
    ) {
      sessionStorage.setItem(
        IS_RES_INITIATED_COUPON_CID,
        JSON.stringify({
          coupons: uiInitiateRequestalues?.coupons,
          contractName: uiInitiateRequestalues?.contract_name,
          contractNumber: uiInitiateRequestalues?.contract_number,
          pathname: location.pathname,
        })
      );
    }

    setDiscountCodeExpanded(false);

    // Adds text to loading-overlay based on location selection
    const overlay = document.querySelector('.loading-overlay');
    if (overlay?.innerHTML) {
      pickup_location?.type === 'CITY' || return_location?.type === 'CITY'
        ? (overlay.innerHTML = `<p>${utils.i18n('finding_your_locations')}</p>`)
        : (overlay.innerHTML = `<p>${utils.i18n('loading_your_vehicles')}</p>`);
    }
  };

  render() {
    const { pickup_location, enablePartnerRewards, disableCouponCode, contractDetails, discountCodesExpanded } =
      this.props;
    const {
      isOneWay,
      isCollapsed,
      isDiscountCollapsed,
      disableGuidedFlow,
      initialValues,
      restrictedDate,
      isLmOrPlanAheadDiscountRemoved,
    } = this.state;
    const pickupInputLabel = utils.i18n(
      isOneWay ? 'location_search_placeholder' : 'location_search_pickup_and_return_placeholder'
    );
    // AEM is sending an empty pickup_location object so account for that
    let selectedLocation;
    if (!utils.gmi.isObjectEmpty(pickup_location)) {
      selectedLocation = pickup_location;
    }

    const returnHomeAnchor = (
      <Anchor
        href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.HOME_PAGE_URL)}
        className='link link--text'
        data-dtm-track={utils.analytics.dtm(ANALYTICS.RES_WIDGET, ANALYTICS.UI_ALERT, ANALYTICS.RETURN_HOME)}
      >
        {utils.i18n('special_rates_not_available_banner_link_label')}
      </Anchor>
    );
    const formId = 'formWrapper';

    return (
      <div id={formId}>
        <Form
          onSubmit={this.handleSubmit}
          validate={validate}
          subscription={{ submitting: true, pristine: true, submitFailed: true }}
          initialValues={initialValues}
          mutators={{ setFieldData }}
          keepDirtyOnReinitialize
        >
          {({ form, handleSubmit, submitFailed }) => (
            <form
              onClick={this.handleFormClick}
              onSubmit={handleSubmit}
              className={cn('booking-widget', {
                'show-discount': !isDiscountCollapsed || discountCodesExpanded,
                'show-discount--with-coupon': !isDiscountCollapsed && !disableCouponCode,
              })}
              autoComplete='off'
              noValidate
              ref={this.clickOutsideRef}
            >
              <ServiceErrors statePath={GMI_SERVICE_PATHS.RESERVATIONS_INITIATE} runAllHandlers />
              {submitFailed && enablePartnerRewards && <FormErrors />}

              {(contractDetails || initialValues?.contractDetails) &&
                (this.isLastMinuteSpecials ||
                  this.isPlanAheadSpecials ||
                  initialValues?.contractIsLmOrPlanAheadSpecials) &&
                restrictedDate &&
                !isLmOrPlanAheadDiscountRemoved && (
                  <GenericNotification
                    key={utils.i18n('special_rates_not_available_banner_title')}
                    messageClass='generic-notification__notifications-on'
                    title={utils.i18n('special_rates_not_available_banner_title')}
                    message={utils.i18n('special_rates_not_available_banner_message', [returnHomeAnchor], {
                      jsx: true,
                    })}
                    dtmTrack={utils.analytics.dtm(ANALYTICS.RES_WIDGET, ANALYTICS.UI_ALERT, ANALYTICS.INVALID_DATES)}
                  />
                )}
              <div className={cn('fieldset fieldset--location-search', { 'round-trip': !isOneWay })}>
                <LocationSearch
                  id='pickupLocation'
                  formId={formId}
                  name='pickupLocation'
                  type={LOCATIONS.LOCATION_SEARCH_TYPES.BOOKING_WIDGET}
                  label={pickupInputLabel}
                  setLocation={this.handleSetLocation()}
                  unsetLocation={this.handleUnsetLocation()}
                  toggleOneWay={!isOneWay ? this.onToggleOneWay : null}
                  isOneWay={isOneWay}
                  selectedLocation={selectedLocation}
                  attention={isCollapsed}
                  isFromBW
                />
              </div>
              {!isCollapsed && this.renderCollapsedSection(submitFailed)}
              <LocationDateTimeFormSpy form={form} />
              <GuidedFormIndicator form={form} disableGuidedFlow={disableGuidedFlow} formRef={this.clickOutsideRef} />
            </form>
          )}
        </Form>
        <Modal
          modalKey={MODAL.PRE_RATE_ADDITIONAL_INFO_MODAL}
          header={utils.i18n('prerate_add_additional_info_main_title')}
          theme={MODAL_THEMES.WHITE}
        >
          <PrerateAdditionalInfoModalContent />
        </Modal>
        <Modal
          modalKey={MODAL.FREQUENT_TRAVELER_RECOVERY_FEE_MODAL}
          header={utils.i18n('partner_rewards_modal_title')}
          theme={MODAL_THEMES.WHITE}
        >
          <FrequentTravelerRecoveryFeeModalContent />
        </Modal>
        <PRPAuthAddProgramModal />
        <PRPAuthConflictModal />
        <RemoveProductCodeModal />
        <VehicleClassFilterModal />
        <CidConflictModal />
      </div>
    );
  }
}

BookingWidget.propTypes = {
  contract_number: PropTypes.string,
  coupons: PropTypes.array,
  couponDetails: PropTypes.object,
  handleInitiate: PropTypes.func.isRequired,
  one_way_rental: PropTypes.bool,
  pickup_location: PropTypes.object,
  pickup_location_id: PropTypes.string,
  pickupLocationFromProps: PropTypes.object,
  renter_age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  return_location: PropTypes.object,
  return_location_id: PropTypes.string,
  setCoupon: PropTypes.func.isRequired,
  setContractNumber: PropTypes.func.isRequired,
  setLocation: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool,
  breakpoint: PropTypes.object,
  contractDetails: PropTypes.object,
  partnerRewardProgram: PropTypes.object,
  enablePartnerRewards: PropTypes.bool,
  setPartnerRewardProgram: PropTypes.func,
  setPartnerRewardProgramMemberId: PropTypes.func,
  isTrueModify: PropTypes.bool,
  profileData: PropTypes.shape({
    age: PropTypes.number,
    partnerRewardsProgram: PropTypes.object,
  }),
  showFrequentTravelerRecoveryFeeModal: PropTypes.func,
  showCidConflictModal: PropTypes.func,
  showUpdateProfileWithPRPModal: PropTypes.func,
  showAuthConflictPRPModal: PropTypes.func,
  disableCouponCode: PropTypes.bool,
  contractCollapsed: PropTypes.bool,
  collapsed: PropTypes.bool,
  bundleProfile: PropTypes.string,
  setBundleProfile: PropTypes.func,
  showProductCode: PropTypes.bool,
  returnLocationCollapsed: PropTypes.bool,
  openRemoveProductCodeModal: PropTypes.func,
  productCode: PropTypes.string,
  rentalStateConfirmed: PropTypes.bool,
  removeInitiateResObject: PropTypes.func,
  reservationObject: PropTypes.object,
  driversAgeInitialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prefilled: PropTypes.bool,
  setAbandonedRes: PropTypes.func,
  getAgeRangeDefaults: PropTypes.func,
  clearDate: PropTypes.func,
  pickupDate: PropTypes.string,
  returnDate: PropTypes.string,
  setDate: PropTypes.func,
  setTime: PropTypes.func,
  unsetLocation: PropTypes.func,
  pickupDateValue: PropTypes.string,
  pickupEligibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  pickupTime: PropTypes.string,
  returnDateValue: PropTypes.string,
  returnEligibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  returnTime: PropTypes.string,
  isDeeplink: PropTypes.bool,
  showVehicleClassFilterModal: PropTypes.func,
  clearInitialVehicleClassFilterData: PropTypes.func,
};

BookingWidget.defaultProps = {
  isCollapsed: false,
  pickupLocationFromProps: {},
};

export default BookingWidget;

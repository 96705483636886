import React, { Component, createRef } from 'react';
import utils from 'utils';
import { SCROLL } from 'constants';
import GMA_RESPONSE_CODES from 'constants/gmaResponseCodes';
import GenericError from 'components/GenericError';
import ServiceErrorHandlerHoc from './ServiceErrorHandlerHoc';
import { propTypes } from './serviceErrorsPropTypes';

/*
 * ServiceErrors displays a warning when there are messages in gmi.messages.gma redux state.
 * ServiceErrors comes wrapped by ServiceErrorHandlerHoc which provides the container logic to connect to the
 * redux serviceErrors and handles specifying errorCodes and statePath to filter which errors to listen to.
 * This means ServiceErrors comes with full error handling functionality of ServiceErrorHandlerHoc
 * (see ServiceErrorHandlerHoc comments for details)
 *
 * Props are passed through (except those using underscore naming convention) so additional functionality is easy to add.
 *
 * ServiceErrors has automatic scroll functionality that scrolls to the warning whenever a newError is added.
 */
class ServiceErrors extends Component {
  static propTypes = propTypes;

  serviceErrorsRef = createRef();

  componentDidUpdate() {
    const { serviceErrorsController } = this.props;
    const { current } = this.serviceErrorsRef;
    if (serviceErrorsController.newError) {
      // placing the scroll in a setTimeout to avoid bug where sometimes it doesn't register, possibly because
      // service loading widget is still on screen when new error is recognized (hasn't had a chance to unmount)
      // and loading widget locks scrolling while it is up
      if (current) {
        setTimeout(() => utils.dom.scrollPage(0, current.offsetTop - SCROLL.HEADER_CLEARANCE));
      }
    }
  }

  render() {
    const { serviceErrorsArray = [] } = this.props.serviceErrorsController;
    const { error = [] } = this.props;
    const errorMessage = [...serviceErrorsArray, ...error];

    // TODO: might need to refactor this condition if we need to hide more than one error based on some error codes
    // Should not render the PRICING_16007 and CROS_RES_VEHICLE_NOT_AVAILABLE error since we have a Sold Out Modal for it
    return (
      errorMessage.length > 0 &&
      (errorMessage.filter(
        (message) =>
          message.code === GMA_RESPONSE_CODES.LOCATION_WITH_NO_VEHICLES_AVAILABLE ||
          message.code === GMA_RESPONSE_CODES.CROS_RES_VEHICLE_NOT_AVAILABLE
      ).length > 0 ? null : (
        <GenericError errors={errorMessage} ref={this.serviceErrorsRef} />
      ))
    );
  }
}

const enhance = utils.compose(ServiceErrorHandlerHoc());

export default enhance(ServiceErrors);

import React from 'react';
import PropTypes from 'prop-types';

const TabsPanel = ({ tabsArray, activeTab }) =>
  tabsArray
    ? tabsArray.map((tab, index) => (
        <section
          role='tabpanel'
          key={index}
          id={`tabpanel_${index}`}
          tabIndex={0}
          aria-labelledby={`tab_${index}`}
          hidden={activeTab !== index}>
          {tab.component}
        </section>
      ))
    : null;

TabsPanel.propTypes = {
  tabsArray: PropTypes.array,
  activeTab: PropTypes.number,
};

export default TabsPanel;

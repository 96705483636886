import { connect } from 'react-redux';
import utils from 'utils';
import { carClassChargesSelector, breakpointSelector } from 'selectors';
import { isDestinationCurrencySelected } from 'selectors/vehicleSelectSelectors';
import TotalPriceVehicleProgressBarDrawer from './TotalPriceVehicleProgressBarDrawer';

const mapStateToProps = state => ({
  breakpoint: breakpointSelector(state),
  vehicleRate: carClassChargesSelector(state) || {},
  isDestinationCurrencySelected: isDestinationCurrencySelected(state),
});

export default connect(mapStateToProps)(utils.toJS(TotalPriceVehicleProgressBarDrawer));

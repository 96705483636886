import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';
import { MODAL, MODAL_THEMES, SCROLL } from 'constants';
import FlyoutTabs from 'components/FlyoutTabs';
import Modal from 'components/Modal';
import Button from 'components/Button';
import MessageBanner from 'components/MessageBanner';
import GenericGboCopyModalContent from 'components/Modal/GenericGboCopyModalContent';
import RecommendedExtrasAlertContent from 'components/modals/RecommendedExtrasAlertContent';
import InPathVehicleUpgradeExtras from 'components/ReservationFlow/InPathVehicleUpgrade/InPathVehicleUpgradeExtras';
import CurrencyConversionModal from 'components/modals/CurrencyConversionModal';
import ExtrasHeader from './ExtrasHeader';
import Bundles from './Bundles';
import IndividualExtras from './IndividualExtras';
import BundlesDetailsModalContent from './Modals/BundlesDetailsModalContent';
import './styles/extras-select.scss';

/**
 * component for Extras Page
 *
 * @param {object}    props.vehicleRate - Selected car rates
 */

class Extras extends Component {
  state = {
    numberOfIndividualExtrasLoading: 0,
  };

  scrollRef = React.createRef();

  handleClickContinue = () => {
    if (this.props.showRecommendedExtrasAlertModal) {
      this.props.openRecommendedExtrasAlertModal();
    } else {
      this.props.extrasComplete();
    }
  };

  componentDidUpdate(prevProps) {
    const { preSelectedExtras, setAllExtras } = this.props;
    if (utils.gmi.isArrayNotEmpty(preSelectedExtras) && preSelectedExtras !== prevProps.preSelectedExtras) {
      setAllExtras(preSelectedExtras);
    }
  }

  // current place of ref it should go - heigh of progress header (this value is okay on mobile too)
  scrollToRef = () => window.scrollTo(0, this.scrollRef.current.offsetTop - SCROLL.HEIGHT_OF_PROGRESS_BAR_DESKTOP);

  toggleExtrasLoading = extraLoadingBool => {
    //  if numberOfIndividualExtrasLoading === 0, we know extras are not loading
    this.setState(({ numberOfIndividualExtrasLoading }) => {
      const newExtraLoadingValue = extraLoadingBool
        ? numberOfIndividualExtrasLoading + 1
        : numberOfIndividualExtrasLoading - 1;
      return {
        numberOfIndividualExtrasLoading: newExtraLoadingValue,
      };
    });
  };

  render() {
    const { hasExtras, includedExtrasAndDrivers, showBundles, vehicleRate, showConvertedTotalsModal } = this.props;
    const { numberOfIndividualExtrasLoading } = this.state;
    const areIndividualExtrasLoading = numberOfIndividualExtrasLoading !== 0;
    const displayCarClass = includedExtrasAndDrivers?.length < 4;
    const estimatedTotal = vehicleRate?.price_summary?.estimated_total_view;
    const estimatedTotalPayment = vehicleRate?.price_summary?.estimated_total_payment;
    const isForeignCurrency = estimatedTotal?.code !== estimatedTotalPayment?.code;

    return (
      <div className='row'>
        <div className='extras-select'>
          <ExtrasHeader
            onClickContinue={this.handleClickContinue}
            hasExtras={hasExtras}
            individualExtraLoading={areIndividualExtrasLoading}
            scrollToRef={this.scrollToRef}
            isForeignCurrency={isForeignCurrency}
            showConvertedTotalsModal={showConvertedTotalsModal}
          />

          <InPathVehicleUpgradeExtras />

          {!hasExtras ? (
            <MessageBanner
              title={utils.i18n('extras_no_extras_available_title')}
              message={utils.i18n('extras_no_extras_available_message')}
            />
          ) : (
            <>
              {includedExtrasAndDrivers.length > 0 && (
                <div
                  className={cn('extras-select__included-section', {
                    'extras-select__included-section--no-bundles': !showBundles,
                  })}>
                  <h2 className='extras-select__included-heading'>
                    {utils.i18n('extras_included_in_your_reservation')}
                  </h2>
                  <FlyoutTabs
                    items={includedExtrasAndDrivers}
                    className='included-extras'
                    contentKey={'detailed_description'}
                    fullWidth
                    icon='extra'
                    hasClearBelow={displayCarClass}
                    isExtrasDrawer
                  />
                </div>
              )}
              {showBundles && (
                <Bundles
                  displayCarClass={displayCarClass}
                  scrollToRef={this.scrollToRef}
                  hasClearAbove={!includedExtrasAndDrivers.length}
                />
              )}
              <IndividualExtras
                shouldShowTitle={showBundles}
                toggleExtrasLoading={this.toggleExtrasLoading}
                scrollRef={this.scrollRef}
              />
              <div className='extras-footer'>
                <Button
                  loading={areIndividualExtrasLoading}
                  disabled={areIndividualExtrasLoading}
                  onClick={this.handleClickContinue}
                  data-dtm-track='button|extras|skip'>
                  {utils.i18n('extras_continue_review_cta_extras_selected')}
                </Button>
              </div>
            </>
          )}
          <Modal modalKey={MODAL.BUNDLES_DETAILS_MODAL} theme={MODAL_THEMES.WHITE}>
            <BundlesDetailsModalContent />
          </Modal>
          <Modal modalKey={MODAL.EXTRAS_EXCLUSION_MODAL} theme={MODAL_THEMES.WHITE}>
            <GenericGboCopyModalContent />
          </Modal>
          <Modal
            modalKey={MODAL.RECOMMENDED_EXTRAS_ALERT_MODAL}
            header={utils.i18n('recommended_alert_header_title')}
            theme={MODAL_THEMES.WHITE}>
            <RecommendedExtrasAlertContent onClickContinueToReview={this.props.extrasComplete} />
          </Modal>
          <CurrencyConversionModal />
        </div>
      </div>
    );
  }
}

Extras.propTypes = {
  hasExtras: PropTypes.bool,
  includedExtrasAndDrivers: PropTypes.array,
  showBundles: PropTypes.bool,
  showRecommendedExtrasAlertModal: PropTypes.bool,
  preSelectedExtras: PropTypes.array,
  openRecommendedExtrasAlertModal: PropTypes.func,
  extrasComplete: PropTypes.func,
  setAllExtras: PropTypes.func,
  vehicleRate: PropTypes.object,
  showConvertedTotalsModal: PropTypes.func,
};

export default Extras;

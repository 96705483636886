import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { i18n } from 'utils/i18n';
import Button from 'components/Button';

const ClearAllButton = ({ hideDesktopHeader, handleClearAll, customClass }) => {
  const clearAllButtonStyles = customClass
    ? cn('link')
    : cn('filter__clear-all', 'link', {
        'filter__location-clear-all': hideDesktopHeader,
      });

  return (
    <Button
      plain
      className={clearAllButtonStyles}
      type='button'
      onClick={handleClearAll}
      aria-label={i18n('common_clear_all')}
    >
      {i18n('common_clear_all')}
    </Button>
  );
};

ClearAllButton.propTypes = {
  hideDesktopHeader: PropTypes.bool,
  handleClearAll: PropTypes.func,
  customClass: PropTypes.string,
};

export default ClearAllButton;

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { i18n } from 'utils/i18n';
import THEMES from 'constants/themes';
import Title from 'components/Title';

import searchIcon from 'images/illutration-secondary-reservations.svg';

/**
 * ReservationLookupTemplate Component
 * Wrapper for the view modify cancel and rental-lookup flow pages that share the same layout.
 *
 * @param {object} props - React Props
 * @param {array|node} props.children - JSX Components passed onto this component to be rendered within it
 *
 * @return {JSX} ReservationLookupTemplate jsx component
 */

const ReservationLookupTemplate = ({
  children,
  titleText,
  shortDesc,
  headerSectionTheme,
  noMinHeight,
  noMarignTop,
  noPaddingTopBottom,
  mainSectionTheme,
  showSideSection,
  isLookupRentalUI,
  postResRentalLookup,
}) => (
  <section
    className={cn('post-res__layout', {
      'post-res__layout--auto-height': noMinHeight,
      'post-res--rental-lookup': postResRentalLookup,
    })}
  >
    <section
      className={cn({
        [`theme--${headerSectionTheme}`]: headerSectionTheme,
        'post-res__layout--auto-height': noMinHeight,
        'post-res__layout--margin-top-spacing': noMarignTop,
      })}
    >
      <section className='aem-Grid aem-Grid--12 aem-Grid--default--12'>
        <div
          className={cn('post-res__section-header aem-GridColumn aem-GridColumn--default--12', {
            'post-res__main-column': !noPaddingTopBottom,
          })}
        >
          <Title type='h1' className='rental-lookup__title' text={i18n(titleText)} />
          {shortDesc && <span className='rental-lookup__desc'>{i18n(shortDesc)}</span>}
        </div>
      </section>
    </section>
    <section
      className={cn('post-res--rental-lookup__main-container', {
        [`theme--${mainSectionTheme}`]: mainSectionTheme,
        'post-res__layout--auto-height': noMinHeight,
        'post-res__layout--margin-top-spacing': noMarignTop,
      })}
    >
      <section className='aem-Grid aem-Grid--12 aem-Grid--tablet--12 aem-Grid--default--12 aem-Grid--phone--12 '>
        <section className='aem-GridColumn--tablet--12 aem-GridColumn--phone--12  aem-GridColumn aem-GridColumn--default--6 '>
          {children}
        </section>
        {showSideSection && (
          <section
            className={cn(
              'post-res__section-side-header padding-top--regular padding-bottom--condensed vertical-separator--large--left aem-GridColumn--tablet--12 aem-GridColumn--phone--12  aem-GridColumn aem-GridColumn--default--6',
              {
                'post-res__section-side-lookupHeader': isLookupRentalUI,
              }
            )}
          >
            <div
              className={cn('post-res__section-side-header__icon', {
                'post-res__section-side-lookupHeader__lookup-icon': isLookupRentalUI,
              })}
            >
              <img src={searchIcon} alt='' />
            </div>
            {!isLookupRentalUI && (
              <div className='post-res__section-side-header__description'>
                <Title type='h2' text={i18n('vmc_side_heading')} />
                <p className='rental-lookup__required'>{i18n('vmc_reservation_text')}</p>
              </div>
            )}
          </section>
        )}
      </section>
    </section>
  </section>
);

ReservationLookupTemplate.defaultProps = {
  headerSectionTheme: THEMES.DARK_BLUE,
  mainSectionTheme: THEMES.LIGHT_BLUE,
};

ReservationLookupTemplate.propTypes = {
  titleText: PropTypes.string,
  shortDesc: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  headerSectionTheme: PropTypes.string,
  mainSectionTheme: PropTypes.string,
  noPaddingTopBottom: PropTypes.bool,
  showSideSection: PropTypes.bool,
};

export default ReservationLookupTemplate;
